import { ColumnLayout, SpaceBetween, Box } from '@cloudscape-design/components';
import { useCatalogManagerContext } from 'pages/catalog/CatalogManager/CatalogManagerPage';

const TemplateDetails = () => {
  const { selectedTemplate } = useCatalogManagerContext();
  return (
    <ColumnLayout columns={3} variant="text-grid">
      {selectedTemplate ? (
        <>
          <SpaceBetween size="l">
            <div>
              <Box variant="awsui-key-label">Friendly Name</Box>
              <div>{selectedTemplate![0]?.friendlyName}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Description</Box>
              <div>{selectedTemplate![0]?.description}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Environment Display Name</Box>
              <div>{selectedTemplate![0]?.envDisplayName}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Default Branch</Box>
              <div>{selectedTemplate![0]?.defaultBranch}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Created At</Box>
              <div>{selectedTemplate![0].createdAt ? selectedTemplate![0]?.createdAt.toLocaleString() : ''}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Updated At</Box>
              <div>{selectedTemplate![0].updatedAt ? selectedTemplate![0]?.updatedAt.toLocaleString() : ''}</div>
            </div>
          </SpaceBetween>
          <SpaceBetween size="l">
            <div>
              <Box variant="awsui-key-label">Repository</Box>
              <div>{selectedTemplate![0]?.repo}</div>
            </div>
          </SpaceBetween>
          <SpaceBetween size="l">
            <div>
              <Box variant="awsui-key-label">Required Parameters</Box>
              <div>{selectedTemplate![0]?.requiredParameters?.join(', ') || '-'}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Expected Outputs</Box>
              <div>{
                Object.keys(selectedTemplate![0]?.expectedOutputs)
                  .map((expectedOutput, idx) => <div key={idx}>Group {expectedOutput}: {Array.isArray(selectedTemplate![0].expectedOutputs[expectedOutput]) ? selectedTemplate![0]?.expectedOutputs[expectedOutput].join(', ') : selectedTemplate![0]?.expectedOutputs[expectedOutput]}</div>)
              }</div>
            </div>
          </SpaceBetween>
        </>
      ) : (
        <i>No data to display</i>
      )}
    </ColumnLayout>
  );
};

export default TemplateDetails;
