import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
} from '@cloudscape-design/components';
import { catalogAPI } from 'api';
import { usePageLayoutContext } from 'components/common/layout';
import DeleteModal from 'components/delete-modal';
import { EmptyState } from 'components/empty-state/EmptyState';
import { API_URL_PATH_LIST_TEMPLATES } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { useCatalogManagerContext } from 'pages/catalog/CatalogManager/CatalogManagerPage';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMatchesCountText } from 'utils';
import { COLUMN_DEFINITIONS } from './table.config';

const Templates = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onDeleteDiscard = () => setShowDeleteModal(false);
  const navigate = useNavigate();

  const {
    allTemplates,
    templateLoading,
    refetchTemplate,
    selectedTemplate,
    setSelectedTemplate,
  } = useCatalogManagerContext();

  const { setNotification } = usePageLayoutContext();

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allTemplates || [],
    {
      filtering: {
        empty: (
          <EmptyState
            title="No template"
            subtitle="No template to display."
            action={
              <Button
                onClick={() =>
                  navigate('/catalog/admin/template/create', {
                    state: {
                      action: 'create',
                    },
                  })
                }
              >
                Create a Template
              </Button>
            }
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: 10 },
      sorting: {},
    }
  );

  useEffect(() => {
    if (allTemplates) {
      if (allTemplates.length > 0 && allTemplates[0]?.id) {
        setSelectedTemplate([allTemplates[0]]);
      }
    }
  }, [allTemplates]);

  const {
    status: deleteTemplateStatus,
    fetchData: deleteTemplate,
    error: deleteTemplateError,
    loading: deleteTemplateLoading,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'DELETE',
      url: `${API_URL_PATH_LIST_TEMPLATES}/${selectedTemplate?.length ? selectedTemplate![0]?.id : ''}`,
    },
    { manual: true }
  );

  useEffect(() => {
    if (deleteTemplateStatus === 200) {
      refetchTemplate();
      setNotification([
        {
          type: 'success',
          content: `Deleted template ${selectedTemplate![0]?.friendlyName} successfully`,
        },
      ]);

      if (allTemplates.length > 0 && allTemplates[0]?.id) {
        setSelectedTemplate([allTemplates[1]]);
      }
    } else if (deleteTemplateStatus > 200) {
      setNotification([
        {
          type: 'error',
          content: deleteTemplateError,
        },
      ]);
    }
  }, [deleteTemplateError, deleteTemplateStatus]);

  const handleButtonDropdownClick = (event: any) => {
    event.preventDefault();
    if (event.detail.id === 'template-delete') {
      setShowDeleteModal(true);
    } else if (event.detail.id === 'template-update') {
      navigate(`/catalog/admin/template/edit/${selectedTemplate![0].id}`, {
        state: {
          action: 'edit',
          id: selectedTemplate![0].id,
        },
      });
    }
  };

  const onDeleteConfirm = () => {
    deleteTemplate();
    setShowDeleteModal(false);
  };

  return (
    <>
      <Table
        {...collectionProps}
        onSelectionChange={({ detail }) => setSelectedTemplate(detail.selectedItems)}
        selectedItems={selectedTemplate}
        loading={templateLoading || deleteTemplateLoading}
        wrapLines={true}
        stripedRows={true}
        resizableColumns={true}
        columnDefinitions={COLUMN_DEFINITIONS}
        items={items}
        loadingText="Loading templates"
        selectionType="single"
        className="table-container"
        variant="embedded"
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringPlaceholder="Find template"
          />
        }
        header={
          <Header
            counter={
              selectedTemplate && selectedTemplate?.length && selectedTemplate[0]?.id
                ? '(' + selectedTemplate.length + `/${allTemplates && allTemplates.length})`
                : `(${allTemplates && allTemplates.length})`
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={refetchTemplate} iconName='refresh' loading={templateLoading} />
                <Button
                  onClick={() =>
                    navigate('/catalog/admin/template/create', {
                      state: {
                        action: 'create',
                      },
                    })
                  }
                >
                  Create
                </Button>
                <ButtonDropdown
                  items={[
                    {
                      text: 'Edit',
                      id: 'template-update',
                      disabled: allTemplates?.length === 0,
                    },
                    {
                      text: 'Delete',
                      id: 'template-delete',
                      disabled: allTemplates?.length === 0,
                    },
                  ]}
                  onItemClick={handleButtonDropdownClick}
                >
                  Actions
                </ButtonDropdown>
              </SpaceBetween>
            }
          >
            List of      Templates
          </Header>
        }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
      />

      {selectedTemplate && selectedTemplate.length > 0 && (
        <DeleteModal
          visible={showDeleteModal}
          onDiscard={onDeleteDiscard}
          onDelete={onDeleteConfirm}
          itemName={selectedTemplate![0]?.friendlyName}
          itemCount={selectedTemplate?.length}
          moduleName="Template"
        />
      )}
    </>
  );
};

export default Templates;
