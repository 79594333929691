import { Routes, Route, Navigate } from 'react-router-dom';

import Layout from 'components/common/layout';
import {
  URL_PATH_CREATE_PHYSICAL_DEVICE,
  URL_PATH_PHYSICAL_DEVICE,
  URL_FULL_PATH_LIST_PHYSICAL_DEVICE,
  URL_PATH_DEVICE_TYPE,
  URL_FULL_PATH_LIST_DEVICE_TYPE,
  URL_PATH_EDIT_PHYSICAL_DEVICE,
  URL_PATH_CREATE_MQTT_CLIENT,
  URL_PATH_CREATE_TAG,
  URL_PATH_CREATE_DEVICE_TYPE,
  URL_PATH_DEVICE,
  URL_FULL_PATH_LIST_DEVICE,
  URL_PATH_INSTALL_DEVICE,
  URL_PATH_LOGIN,
  URL_PATH_UNAUTHORIZED,
  URL_PATH_CATALOG_MANAGER,
  URL_PATH_CREATE_CATALOG_DEPLOYMENT,
  URL_PATH_UPDATE_CATALOG_DEPLOYMENT,
  URL_PATH_CREATE_CATALOG_TEMPLATE,
  URL_PATH_UPDATE_CATALOG_TEMPLATE,
  URL_PATH_CATALOG_SHOPPING,
  URL_PATH_CATALOG_ADMIN,
  URL_PATH_CATALOG_USER,
  URL_PATH_UPDATE_CATALOG_SHOPPING_DEPLOYMENT,
  URL_PATH_EDIT_DEVICE_TYPE,
  URL_PATH_CREATE_DEVICE,
  URL_PATH_CREATE_CATALOG_APPLICATION,
  URL_PATH_UPDATE_CATALOG_APPLICATION,
  URL_PATH_CREATE_CATALOG_INTEGRATION,
  URL_PATH_UPDATE_CATALOG_INTEGRATION,
  URL_PATH_DUPLICATE_EDIT_CATALOG_INTEGRATION,
} from 'constants/urls';
import DeviceManager from 'pages/device-manager';
import CreateDevicePage from 'pages/device-manager/CreateDevicePage';
import ListDevicesPage from 'pages/device-manager/ListDevicesPage';
import DeviceTypeManager from 'pages/device-type-manager';
import CreateDeviceTypePage from 'pages/device-type-manager/CreateDeviceTypePage';
import ListDeviceTypesPage from 'pages/device-type-manager/ListDeviceTypesPage';
import PhysicalDeviceManager from 'pages/physical-device-manager';
import CreateMqttClientPage from 'pages/physical-device-manager/CreateMqttClientPage';
import CreatePhysicalDevicesPage from 'pages/physical-device-manager/CreatePhysicalDevicesPage';
import CreateTagPage from 'pages/physical-device-manager/CreateTagPage';
import ListPhysicalDevicesPage from 'pages/physical-device-manager/ListPhysicalDevicesPage';
import UpdatePhysicalDevicePage from 'pages/physical-device-manager/UpdatePhysicalDevicePage';
import Login from 'pages/login';
import RequireAuth from 'setup/auth/RequireAuth';
import Unauthorized from 'pages/unauthorized';
import CatalogManager from 'pages/catalog/CatalogManager';
import CatalogManagerPage from 'pages/catalog/CatalogManager/CatalogManagerPage';
import React from 'react';
import UpdateApplicationPage from 'pages/catalog/UpdateApplicationPage';
import CatalogShopping from 'pages/catalog/CatalogShopping';
import CatalogShoppingPage from 'pages/catalog/CatalogShopping/CatalogShoppingPage';
import InstallDevicePage from 'components/install-page';
import CreateTemplatePage from 'pages/catalog/CatalogManager/CreateTemplatePage';
import UpdateTemplatePage from 'pages/catalog/CatalogManager/UpdateTemplatePage';
import CreateDeploymentPage from 'pages/catalog/CreateDeploymentPage';
import UpdateDeploymentPage from 'pages/catalog/UpdateDeploymentPage';
import CreateApplicationPage from 'pages/catalog/CreateApplicationPage';
import CreateIntegrationPage from 'pages/catalog/CreateIntegrationPage';
import UpdateIntegrationPage from 'pages/catalog/UpdateIntegrationPage';
import DuplicateIntegrationPage from 'pages/catalog/DuplicateIntegrationPage';

const RoutesManager: React.FC = () => {
  return (
    <Routes>
      {/* Login route */}
      <Route path={URL_PATH_LOGIN} element={<Login />} />

      <Route element={<RequireAuth allowedGroups={[]} />}>
        <Route path='/' element={<Layout />}>
          <Route
            path={URL_PATH_CREATE_PHYSICAL_DEVICE}
            element={<Navigate to={URL_PATH_CREATE_PHYSICAL_DEVICE} replace />}
          />

          <Route
            path={URL_PATH_PHYSICAL_DEVICE}
            element={<Navigate to={URL_FULL_PATH_LIST_PHYSICAL_DEVICE} replace />}
          />

          <Route path={URL_PATH_DEVICE_TYPE} element={<Navigate to={URL_FULL_PATH_LIST_DEVICE_TYPE} replace />} />

          <Route path={URL_PATH_CATALOG_ADMIN} element={<Navigate to={URL_PATH_CATALOG_MANAGER} replace />} />

          <Route
            element={
              <RequireAuth
                allowedGroups={[
                  window['runtimeConfig'].idpAzureAdAdminsGroup,
                  window['runtimeConfig'].cognitoAdminsGroup,
                ]}
              />
            }
          >
            {/*Physical device manager routes*/}
            <Route path={URL_PATH_PHYSICAL_DEVICE} element={<PhysicalDeviceManager />}>
              <Route path={URL_FULL_PATH_LIST_PHYSICAL_DEVICE} element={<ListPhysicalDevicesPage />} />
              <Route path={URL_PATH_CREATE_PHYSICAL_DEVICE} element={<CreatePhysicalDevicesPage />} />
              <Route path={URL_PATH_EDIT_PHYSICAL_DEVICE} element={<UpdatePhysicalDevicePage />} />
              <Route path={URL_PATH_CREATE_MQTT_CLIENT} element={<CreateMqttClientPage />} />
              <Route path={URL_PATH_CREATE_TAG} element={<CreateTagPage />} />
            </Route>

            {/*Device types manager routes*/}
            <Route path={URL_PATH_DEVICE_TYPE} element={<DeviceTypeManager />}>
              <Route path={URL_FULL_PATH_LIST_DEVICE_TYPE} element={<ListDeviceTypesPage />} />

              <Route path={URL_PATH_CREATE_DEVICE_TYPE} element={<CreateDeviceTypePage />} />

              <Route path={`${URL_PATH_EDIT_DEVICE_TYPE}/:deviceTypeName`} element={<CreateDeviceTypePage />} />
            </Route>

            {/* Device manager routes */}
            <Route path={URL_PATH_DEVICE} element={<DeviceManager />}>
              <Route path={URL_FULL_PATH_LIST_DEVICE} element={<ListDevicesPage />} />
              <Route path={URL_PATH_CREATE_DEVICE} element={<CreateDevicePage />} />
              <Route path={`${URL_PATH_INSTALL_DEVICE}/:deviceName`} element={<InstallDevicePage />} />
            </Route>

            {/*Catalog manager routes*/}
            <Route path={URL_PATH_CATALOG_ADMIN} element={<CatalogManager />}>
              <Route path={URL_PATH_CATALOG_MANAGER} element={<CatalogManagerPage />} />
              <Route path={URL_PATH_CREATE_CATALOG_APPLICATION} element={<CreateApplicationPage />} />
              <Route path={URL_PATH_UPDATE_CATALOG_APPLICATION} element={<UpdateApplicationPage />} />
              <Route path={URL_PATH_CREATE_CATALOG_INTEGRATION} element={<CreateIntegrationPage />} />
              <Route path={URL_PATH_UPDATE_CATALOG_INTEGRATION} element={<UpdateIntegrationPage />} />
              <Route path={URL_PATH_DUPLICATE_EDIT_CATALOG_INTEGRATION} element={<DuplicateIntegrationPage />} />
              <Route path={URL_PATH_CREATE_CATALOG_DEPLOYMENT} element={<CreateDeploymentPage />} />
              <Route path={URL_PATH_UPDATE_CATALOG_DEPLOYMENT} element={<UpdateDeploymentPage />} />
              <Route path={URL_PATH_CREATE_CATALOG_TEMPLATE} element={<CreateTemplatePage />} />
              <Route path={URL_PATH_UPDATE_CATALOG_TEMPLATE} element={<UpdateTemplatePage />} />
            </Route>
          </Route>

          <Route
            element={
              <RequireAuth
                allowedGroups={[
                  window['runtimeConfig'].idpAzureAdAdminsGroup,
                  window['runtimeConfig'].cognitoAdminsGroup,
                  window['runtimeConfig'].idpAzureAdUsersGroup,
                  window['runtimeConfig'].cognitoUsersGroup,
                ]}
              />
            }
          >
            {/*Catalog shopping routes*/}
            <Route path={URL_PATH_CATALOG_USER} element={<CatalogShopping />}>
              <Route path={URL_PATH_CATALOG_SHOPPING} element={<CatalogShoppingPage />} />
              <Route path={URL_PATH_UPDATE_CATALOG_SHOPPING_DEPLOYMENT} element={<UpdateApplicationPage />} />
            </Route>
          </Route>

          <Route path="" element={<Navigate to={URL_FULL_PATH_LIST_PHYSICAL_DEVICE} />} />

          {/* Unauthorized */}
          {/* <Route element={<RequireAuth allowedRoles={[]} />}> */}
          <Route path={URL_PATH_UNAUTHORIZED} element={<Unauthorized />} />
          {/* </Route> */}
        </Route>
      </Route>

      <Route path="*" element={<Navigate to={URL_FULL_PATH_LIST_PHYSICAL_DEVICE} replace />} />
    </Routes>
  );
};

export default RoutesManager;
