export interface IDevicesListProps {
  attributes: {
    friendlyName: string;
    host: string;
  };
  category: string;
  templateId: string;
  name: string;
  groupPath: string;
  description: string;
  parentPath: string;
  relation: string;
  direction: string;
}

export interface IDevicesResponse {
  results: IDevicesListProps[];
}

export interface ISelectedDevice {
  selectedItem: IDevicesListProps[];
}

export interface CreateDeviceProps {
  id: string;
  lookupId: string;
  invoiceId: string;
  physicalDeviceId: string;
  friendlyName: string;
  description: string;
  deviceEUI: string;
  appEUI: string;
  deviceTypeId: string;
  deviceGroupId: string;
  appKey: string;
}

export type PhysicalDeviceResponseProps = {
  attributes: {
    host: 'string';
    friendlyName: 'string';
    credential: {
      userId: 'string';
      passowrd: 'string';
      ts: number;
    };
  };
  groups: {
    tektelicdevicemodelandphysicaldevice: string[];
    devicetypeandphysicaldevice: string[];
    deviceconnectedphysicaldevice: string[];
    physicaldevicetype: string[];
    tag: string[];
  };
  category: string;
  templateId: string;
  name: string;
  groupPath: string;
  parentPath: string;
  description: string;
};

export type DeviceTypesListProps = {
  attributes: {
    manufacturer: string;
    purpose: string;
    protocol: string;
    snowId: string;
    deviceClass: string;
    state: string;
    normalizer: string;
    decoder: string;
    friendlyName: string;
  };
  measurements: Measurement[];
  downlinks: Downlink[];
  category: string;
  templateId: string;
  name: string;
  groupPath: string;
  parentPath: string;
  description: string;
  relation: string;
  direction: string;
};

export type DeviceTypesResponse = {
  results: DeviceTypesListProps[];
};

export type SelectedDeviceTypes = {
  selectedItem: DeviceTypesListProps[];
};

export type DeviceTypeProps = {
  attributes: {
    physicalDeviceId: 'string';
    invoiceId: 'string';
    deviceTypeId: 'string';
    deviceState: 'string';
    tektelicDeviceId: 'string';
  };
  category: 'string';
  templateId: 'string';
  name: 'string';
  groupPath: 'string';
  parentPath: 'string';
};

export type DeviceResponse = {
  results: DeviceTypeProps[];
};

export type DeviceProps = {
  attributes: {
    physicalDeviceId: string;
    invoiceId: string;
    lookupId: string;
    serialNumber: string;
    deviceTypeId: string;
    deviceState: string;
    tektelicDeviceId?: string;
    iotApplicationId?: string;
    iotApplicationName?: string;
    previousPath?: string;
    currentPath?: string;
    friendlyCurrentPath?: string;
    friendlyName: string;
    snowSysId?: string;
  };
  category: string;
  templateId: string;
  description: string;
  name: string;
  groupPath: string;
  parentPath: string;
};

export type AllTagsResponseProps = {
  attributes: {
    friendlyName: string;
  };
  category: string;
  templateId: string;
  name: string;
  groupPath: string;
  parentPath: string;
  description: string;
  relation: string;
  direction: string;
};

export type DeploymentState = 'started' | 'deploying' | 'running' | 'failed' | 'deleted' | 'destroying';

export interface Deployment {
  id: string;
  name: string;
  description: string;
  templateId: string;
  templateName: string;
  accountId: string;
  region: string;
  parameters: {[key: string]: string};
  state: DeploymentState;
  stateDetails?: string;
  telemetryName?: string;
  telemetryArn?: string;
  applicationRoleArn?: string;
  executionId?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export type Application = {
  id: string;
  name: string;
  description: string;
  ownerUserName: string;
  parentCI: string;
  updatedAt: string;
  integrations: string[];
  devicesName: string[];
  createdAt: string;
};

export type TemplateDto = {
  id: string;
  friendlyName: string;
  description: string;
  requiredParameters: string[];
  expectedOutputs: { [group: string] : string[] };
  repo: string;
  defaultBranch: string;
  envDisplayName: string;
  createdAt: Date;
  updatedAt: Date;
}

export type Account = {
  accountId: string;
  updatedAt: string;
  iotApplicationId: string;
  installerRoleArn: string;
  state: string;
};

export enum MeasurementType {
  Temperature = 'temperature',
  Percentage = 'percentage',
  Custom = 'custom',
  Number = 'number',
  Boolean = 'boolean'
};

export type Measurement = {
  name: string;
  type: MeasurementType | undefined;
  unit?: string;
  favourite: boolean;
};

export type Downlink = {
  name: string;
  description: string;
  data: string;
  port: number;
  confirmed: boolean;
};

export type CustomDownlink = {
  customData: string;
  port: number;
  confirmed: boolean;
};

export type StandardDownlinkMutationPayload = {
  downlinkName: string;
};

export type CustomDownlinkMutationPayload = {
  customData: string;
  port: number;
  confirmed: boolean;
};

export type DeviceTypeResponse = {
  deviceTypeId: string;
  measurements: Measurement[];
  downlinks: Downlink[];
};

export type Integration = {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  applicationId: string;
  status: boolean;
  type: string;
  stateCode: string;
  stateDetail: string
};

export enum IntegrationStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum IntegrationTypes {
  MQTTS = 'MQTTS',
  HTTPS = 'HTTPS',
  KINESIS = 'KINESIS',
  DEPLOYMENT = 'DEPLOYMENT',
  IOT_CORE = 'IOT_CORE',
  DATALAND = 'DATALAND',
}

export enum RequestTypes {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
}

export type ParametersProps = {
  name: string,
  value: string,
}
