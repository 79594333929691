import { Container } from '@cloudscape-design/components';
import CreateDeviceType from 'components/device-type-manager/CreateDeviceType';

const CreateDeviceTypePage = () => {
  return (
    <Container>
      <CreateDeviceType />
    </Container>
  );
};

export default CreateDeviceTypePage;
