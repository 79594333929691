import { createContext, useContext, useEffect, useState } from 'react';
import { extractFilteringAttributes } from 'utils';
import { ColumnLayout } from '@cloudscape-design/components';
import { catalogAPI, platformCoreAPI } from 'api';
import CatalogDetailsTabs from 'components/catalog-manager/CatalogDetailsTabs';
import CatalogMainTabs from 'components/catalog-manager/CatalogMainTabs';
import {
  API_URL_PATH_LIST_ACCOUNTS,
  API_URL_PATH_LIST_APPLICATIONS,
  API_URL_PATH_LIST_DEPLOYMENTS,
  API_URL_PATH_LIST_TEMPLATES,
  API_URL_PATH_DEVICE,
  API_URL_PATH_LIST_INTEGRATION,
} from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { usePageLayoutContext } from 'components/common/layout';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  Account,
  Deployment,
  TemplateDto,
  Application,
  DeviceProps,
  Integration,
} from 'types/custom';
import Spinner from 'components/spinner/Spinner';

const CatalogManagerContext = createContext({
  allApplications: [] as Application[],
  allIntegrations: [] as Integration[],
  allDeployments: [] as Deployment[],
  allTemplates: [] as TemplateDto[],
  allAccounts: [] as Account[],
  applicationsError: '',
  integrationsError: '',
  deploymentsError: '',
  templateError: '',
  accountsError: '',
  applicationsLoading: false,
  integrationsLoading: false,
  deploymentsLoading: false,
  templateLoading: false,
  accountsLoading: false,
  selectedApplication: [] as Application[] | undefined,
  selectedIntegration: [] as Integration[] | undefined,
  selectedDeployment: [] as Deployment[] | undefined,
  selectedTemplate: [] as TemplateDto[] | undefined,
  selectedAccount: [] as Account[] | undefined,
  setSelectedApplication: (state: Application[]) => { },
  setSelectedIntegration: (state: Integration[]) => { },
  setSelectedDeployment: (state: Deployment[]) => { },
  setSelectedTemplate: (state: TemplateDto[]) => { },
  setSelectedAccount: (state: Account[]) => { },
  applicationsRefetch: () => { },
  integrationsRefetch: () => { },
  deploymentsRefetch: () => { },
  refetchTemplate: () => { },
  accountsRefetch: () => { },
  activeMainTabId: '',
  activeMainDetailsTabId: '',
  setActiveMainTabId: (state: string) => { },
  setActiveMainDetailsTabId: (state: string) => { },
});

export const useCatalogManagerContext = () => useContext(CatalogManagerContext);

const CatalogManagerPage = () => {
  const [allApplications, setAllApplications] = useState<Application[]>([]);
  const [allDeployments, setAllDeployments] = useState<Deployment[]>([]);
  const [allTemplates, setAlTemplates] = useState<TemplateDto[]>([]);
  const [allAccounts, setAllAccounts] = useState<Account[]>([]);
  const [allIntegrations, setAllIntegration] = useState<Integration[]>([]);
  const [selectedApplication, setSelectedApplication] = useState<Application[]>();
  const [selectedDeployment, setSelectedDeployment] = useState<Deployment[]>();
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateDto[]>();
  const [selectedAccount, setSelectedAccount] = useState<Account[]>();
  const [activeMainTabId, setActiveMainTabId] = useState('applications');
  const [activeMainDetailsTabId, setActiveMainDetailsTabId] = useState('applicationsDetails');
  const [allDevices, setAllDevices] = useState<DeviceProps[]>([]);
  const [selectedIntegration, setSelectedIntegration] = useState<Integration[]>([]);

  const { setNotification } = usePageLayoutContext();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setActiveMainDetailsTabId(() => activeMainTabId + 'Details');
  }, [activeMainTabId])

  useEffect(() => {
    const locationState = location?.state;
    if (locationState?.action) {
      setNotification([
        {
          type: 'success',
          content: locationState.message,
        },
      ]);

      if (locationState.tab) {
        setActiveMainTabId(locationState.tab);
      }
      if (locationState.detailsTab) {
        setActiveMainDetailsTabId(locationState.detailsTab);
      }
    }

    navigate(location.pathname, {});
  }, []);

  const {
    loading: integrationsLoading,
    error: integrationsError,
    fetchData: fetchIntegration,
    refetch: integrationsRefetch,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: `${API_URL_PATH_LIST_INTEGRATION}/application/${selectedApplication && selectedApplication.length > 0 ? selectedApplication![0].id : ''}`,
    },
    { manual: true }
  );

  const {
    response: devicesResponse,
    loading: devicesLoading,
  } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'GET',
      url: API_URL_PATH_DEVICE,
    },
    { manual: false }
  );

  const {
    response: applicationsResponse,
    loading: applicationsLoading,
    error: applicationsError,
    refetch: applicationsRefetch,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_APPLICATIONS,
    },
    { manual: false }
  );

  const {
    response: deploymentsResponse,
    loading: deploymentsLoading,
    error: deploymentsError,
    refetch: deploymentsRefetch,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_DEPLOYMENTS,
    },
    { manual: false }
  );

  const {
    response: templateResponse,
    loading: templateLoading,
    error: templateError,
    refetch: refetchTemplate,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_TEMPLATES,
    },
    { manual: false }
  );


  const {
    response: accountsResponse,
    loading: accountsLoading,
    error: accountsError,
    refetch: accountsRefetch,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_ACCOUNTS,
    },
    { manual: false }
  );

  useEffect(() => {
    if (devicesResponse) {
      setAllDevices(extractFilteringAttributes(devicesResponse.results) || []);
    }
  }, [devicesResponse]);

  useEffect(() => {
    if (applicationsResponse) {
      setAllApplications(applicationsResponse?.items || []);
    }
  }, [applicationsResponse]);

  useEffect(() => {
    if (allApplications.length !== 0 && allDevices.length !== 0) {
      allApplications.forEach((application: Application) => {
        let deviceNamesForApplication: string[] = allDevices
          .filter((device: DeviceProps) => device.attributes.iotApplicationId === application.id)
          .map((device: DeviceProps) => device.name)
        application.devicesName = deviceNamesForApplication;
      })
      setAllApplications(allApplications);
    }
  }, [allDevices, allApplications])

  useEffect(() => {
    if (deploymentsResponse) {
      setAllDeployments(deploymentsResponse?.items || []);
    }
  }, [deploymentsResponse]);

  useEffect(() => {
    if (templateResponse) {
      setAlTemplates(templateResponse?.items);
    }
  }, [templateResponse]);

  useEffect(() => {
    if (accountsResponse) {
      setAllAccounts(accountsResponse?.items || []);
    }
  }, [accountsResponse]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchIntegration()
      if (response && response.status === 200) {
        setAllIntegration(response.data || []);
      }
    };
    if (selectedApplication && selectedApplication.length > 0) {
      fetchData();
    }
  }, [selectedApplication])

  useEffect(() => {
    if (allIntegrations && allIntegrations.length > 0) {
      setSelectedIntegration([allIntegrations[0]]);
    }
  }, [allIntegrations]);

  return (
    <CatalogManagerContext.Provider
      value={{
        allApplications,
        allIntegrations,
        allDeployments,
        allTemplates,
        allAccounts,
        applicationsError,
        integrationsError,
        deploymentsError,
        templateError,
        accountsError,
        applicationsLoading,
        integrationsLoading,
        deploymentsLoading,
        templateLoading,
        accountsLoading,
        selectedApplication,
        selectedIntegration,
        selectedDeployment,
        selectedTemplate,
        selectedAccount,
        setSelectedApplication,
        setSelectedIntegration,
        setSelectedDeployment,
        setSelectedTemplate,
        setSelectedAccount,
        applicationsRefetch,
        integrationsRefetch,
        deploymentsRefetch,
        refetchTemplate,
        accountsRefetch,
        activeMainTabId,
        setActiveMainTabId,
        activeMainDetailsTabId,
        setActiveMainDetailsTabId,
      }}
    >
      <ColumnLayout variant="text-grid">
        {(applicationsLoading || devicesLoading) && !selectedApplication && (
          <>
            <br></br>
            <Spinner />
          </>
        )}
        {applicationsResponse && devicesResponse && <CatalogMainTabs />}
      </ColumnLayout>
      {applicationsResponse && devicesResponse && (
        <>
          <ColumnLayout variant="text-grid">
            <CatalogDetailsTabs />
          </ColumnLayout>
        </>
      )}
      <Outlet />
    </CatalogManagerContext.Provider>
  );
};

export default CatalogManagerPage;
