import { SpaceBetween, Header, FormField, Input, Form, Select, SelectProps, AttributeEditor, Box, Alert, TextContent, Table, Button, TokenGroup, Container, Grid, Toggle } from '@cloudscape-design/components';
import { catalogAPI } from 'api';
import Spinner from 'components/spinner/Spinner';
import {
  URL_PATH_CATALOG_MANAGER,
  API_URL_PATH_LIST_INTEGRATION,
  URL_PATH_CREATE_CATALOG_INTEGRATION,
  API_URL_PATH_LIST_DEPLOYMENTS,
} from 'constants/urls';
import useFetch from 'hooks/useFetch';
import useFetchWithReactQuery from 'hooks/useFetchWithReactQuery';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Deployment, Integration, IntegrationStatus, IntegrationTypes, RequestTypes } from 'types/custom';
import { selectIntegrationEnum, selectIntegrationStatusProps, selectIntegrationTypes } from 'utils';

const Control = React.memo(
  ({ value, index, setOutputItems, prop }: { value: any; index: any; setOutputItems: any; prop: string }) => {
    return (
      <Input
        value={value}
        onChange={({ detail }) => {
          setOutputItems((items: any) => {
            const updatedItems = [...items];
            updatedItems[index] = {
              ...updatedItems[index],
              [prop]: detail.value,
            };
            return updatedItems;
          });
        }}
      />
    );
  }
);

const CreateIntegration = () => {
  const [id, setId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [separateEvents, setSeparateEvents] = useState<boolean>(false);
  const [formatterLambdaArn, setFormatterLambdaArn] = useState<string>('');
  const [typeToUpdate, setTypeToUpdate] = useState<string>('');
  const [selectedApplicationId, setSelectedApplicationId] = useState('');
  const [hasDatalandIntegration, setHasDatalndIntegration] = useState<boolean>(false);
  const [hostName, setHostName] = useState<string>('');
  const [port, setPort] = useState<string>('');
  const [path, setPath] = useState<string>('');
  const [username, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [privateKey, setPrivateKey] = useState<string>('');
  const [publicKey, setPublicKey] = useState<string>('');
  const [ca, setCa] = useState<string>('');
  const [headers, setHeaders] = useState([{}]);
  const [timeout, setTimeout] = useState<string>('');
  const [kinesisArn, setKinesisArn ] = useState<string>('');
  const [applicationRoleArn, setApplicationRoleArn] = useState<string>('');
  const [deployments, setDeployments ] = useState<SelectProps.Options>([]);
  const [statusIntegration, setStatusIntegration] = useState<SelectProps.Option>({
    value: '',
    label: '',
  });
  const [type, setType] = useState<SelectProps.Option>({
    value: '',
    label: '',
  });
  const [requestType, setRequestType] = useState<SelectProps.Option>({
    value: '',
    label: '',
  });
  const [topicCustomText, setTopicCustomText] = useState<string>('');
  const [selectedDeployment, setSelectedDeployment] = useState<SelectProps.Option | null>(null);

  const [isEditFlow, setIsEditFlow] = useState(false);
  const [isDuplicateFlow, setIsDuplicateFlow] = useState(false);
  const [disableFormFields, setDisableFormFields] = useState(false);

  const topicVariables = [                
    {
      name: "$NAME",
      description: "The device name (starting with IOT)."
    },
    {
      name: "$SERVER_TIME",
      description: "Timestamp obtained from the AWS lambda."
    },
    {
      name: "$SERVER_FULL_TIME",
      description: "Full ISO date and time from the AWS lambda."
    },
    {
      name: "$MEASUREMENT",
      description: "Measurement name."
    },
    {
      name: "$VALUE",
      description: "Measurement value."
    },
    {
      name: "$TIME",
      description: "Timestamp obtained from the payload."
    },
    {
      name: "$FULL_TIME",
      description: "Full USO date and time obtained from the payload."
    }
  ]

  const [selectedVariables, setSelectedVariables] = useState<string[]>([]);

  const navigate = useNavigate();
  const location = useLocation();
  let params = useParams();

  useEffect(() => {
    if (
      (location && location.state?.action === 'edit') ||
      (location.pathname && location.pathname.indexOf('edit') > -1)
    ) {
      setIsEditFlow(true);
    } else if (location && location.state?.action === 'create') {
      setSelectedApplicationId(location.state.applicationId)
    } else if (
      (location && location.state?.action === 'duplicate') ||
      (location.pathname && location.pathname.indexOf('duplicate') > -1)
    ) {
      setIsDuplicateFlow(true);
      setSelectedApplicationId(location.state.applicationId)
    }
  }, []);

  const {
    loading: integrationLoading,
    error: integrationError,
    fetchData: getIntegration,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_INTEGRATION,
      param: params?.id,
    },
    { manual: true }
  );

  const {
    loading: integrationForApplicationLoading,
    error: integrationForApplicationError,
    fetchData: getIntegrationForApplication,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: `${API_URL_PATH_LIST_INTEGRATION}/application/${selectedApplicationId ? selectedApplicationId : ''}`,
    },
    { manual: true }
  );

  useEffect(() => {
    if (isEditFlow) {
      getIntegration()
        .then((integration) => {
          if (integration?.data) {
            setId(integration.data.id);
            setDescription(integration.data.description);
            setName(integration.data.name);
            setStatusIntegration(selectIntegrationStatusProps(integration.data));
            setTypeToUpdate(integration.data.type);
          } else {
            const navUrl = URL_PATH_CREATE_CATALOG_INTEGRATION;
            navigate(navUrl);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (isDuplicateFlow) {
      getIntegration()
        .then((integration) => {
          if (integration?.data) {
            setDescription(integration.data.description);
            setName(integration.data.name);
            setType(selectIntegrationTypes(integration.data));
          } else {
            const navUrl = URL_PATH_CREATE_CATALOG_INTEGRATION;
            navigate(navUrl);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isEditFlow, isDuplicateFlow]);

  useEffect(() => {
    if (selectedApplicationId) {
      getIntegrationForApplication()
        .then((integrations) => {
          const data = integrations?.data;
          if (data && data.some((integration: Integration) => integration.type === IntegrationTypes.DATALAND)) {
            setHasDatalndIntegration(true);
          }
        })
        .catch(() => {
          console.error(JSON.stringify(integrationForApplicationError));
        });
    }
  }, [selectedApplicationId])

  const {
    error: updateIntegrationError,
    loading: updateIntegrationLoading,
    status: updateIntegrationStatus,
    fetchData: updateApp,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'PATCH',
      url: `${API_URL_PATH_LIST_INTEGRATION}/${id}`,
      headers: {
        'Content-Type': 'integration/json',
      },
      data: {
        description,
        name,
        status: statusIntegration.label === "ENABLED",
        type: typeToUpdate,
        topic: selectedVariables ? `/${selectedVariables.join('/')}` : ''
      },
    },
    { manual: true }
  );

  const { error, loading, status, fetchData } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'POST',
      url: API_URL_PATH_LIST_INTEGRATION,
      headers: {
        'Content-Type': 'integration/json',
      },
      data: (() => {
        const data = {
          applicationId: selectedApplicationId,
          name,
          description,
          separateEvents,
          type: type.label,
          hostName,
          requestType: requestType ? requestType.label : '',
          port: +port,
          path,
          username,
          password,
          publicKey,
          privateKey,
          timeout: +timeout,
          headers,
          ca,
          formatterLambdaArn,
          deploymentId: selectedDeployment ? selectedDeployment.value : '',
          kinesisArn,
          topic: selectedVariables ? `/${selectedVariables.join('/')}` : '',
          applicationRoleArn,
        };

        return Object.fromEntries(
          Object.entries(data).filter(([key, value]) => value !== '')
        );
      })(),
    },
    { manual: true }
  );

  const { data: deploymentResponse, isLoading, refetch, isFetching } = useFetchWithReactQuery<{ items: Deployment[] }>({
    key: `integration`,
    url: API_URL_PATH_LIST_DEPLOYMENTS,
    axiosInstance: catalogAPI,
    enabled: type.label === IntegrationTypes.DEPLOYMENT,
    placeholderData: { items: [] },
  });

  useEffect(() => {
    if (type.label === IntegrationTypes.DEPLOYMENT && deployments.length === 0) {
      setDeployments(deploymentResponse.items.map((deployment: Deployment) => ({
        value: deployment.id,
        label: deployment.name,
      })))
    }
  }, [deploymentResponse])

  useEffect(() => {
    if (status === 201 || updateIntegrationStatus === 200) {
      let stateObj = {};

      if (isEditFlow) {
        stateObj = {
          action: 'update-integration',
          status: 'success',
          message: `Successfully updated integration ${id}`,
          tab: 'applications',
          detailsTab: 'integrationsDetails',
        };
      } else if (isDuplicateFlow) {
        stateObj = {
          action: 'duplicate-integration',
          status: 'success',
          message: `Successfully duplicated integration ${name}`,
          tab: 'applications',
          detailsTab: 'integrationsDetails',
        };
      } else {
        stateObj = {
          action: 'create-integration',
          status: 'success',
          message: `Successfully created integration ${id}`,
          tab: 'applications',
          detailsTab: 'integrationsDetails',
        };
      }

      navigate(URL_PATH_CATALOG_MANAGER, {
        state: stateObj,
      });
    } else if (status > 201 || updateIntegrationStatus > 201) {
      setDisableFormFields(false);
    }
  }, [status, updateIntegrationStatus]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableFormFields(true);

    if (isEditFlow) {
      await updateApp();
    } else {
      await fetchData();
    }
  };

  const selectedButtons = (): JSX.Element => {
    return <div>
      <TokenGroup
        onDismiss={({ detail }) => setSelectedVariables(selectedVariables.filter((_, itemIndex) => itemIndex !== detail.itemIndex))}
        items={selectedVariables.map((variable) => {
          return { label: `/${variable}`, value: variable };
        })}/>
    </div>
  };

  const allButtons = <SpaceBetween direction='horizontal' size="xs">
    {topicVariables.map((variable) => {
      const { name } = variable;
      return <Button
        wrapText={false}
        key={name}
        variant="primary"
        disabled={selectedVariables.includes(name)}
        onClick={() => {
          setSelectedVariables([...selectedVariables, name]);
        }}
      >
        {name}
      </Button>
    })
    }
  </SpaceBetween>


  return (
    <form onSubmit={handleSubmit}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="link" onClick={() => navigate(URL_PATH_CATALOG_MANAGER, {})}>
              Cancel
            </Button>
            <Button variant="primary" loading={loading || updateIntegrationLoading}>
              Submit
            </Button>
          </SpaceBetween>
        }
        header={
          <Header variant="h2" description="">
            {isEditFlow ? 'Update' : 'Create'} a Integration
          </Header>
        }
        errorText={error || integrationError || updateIntegrationError}
      >
        <SpaceBetween direction="vertical" size="xl">
          {integrationLoading || integrationForApplicationLoading ? (
            <Spinner />
          ) : (
            <>
              <FormField label="Name">
                <Input
                  disabled={disableFormFields}
                  value={name}
                  onChange={(event) => setName(event.detail.value)}
                />
              </FormField>
              <FormField label="Description">
                <Input
                  disabled={disableFormFields}
                  value={description}
                  onChange={(event) => setDescription(event.detail.value)}
                />
              </FormField>
                <FormField>
                  <Toggle
                    onChange={({ detail }) =>
                      setSeparateEvents(detail.checked)
                    }
                    checked={separateEvents}
                  >
                    Separate Events
                  </Toggle>
                </FormField>
                <FormField label="Formatter lambda ARN">
                  <Input
                    disabled={disableFormFields}
                    value={formatterLambdaArn}
                    onChange={(event) => setFormatterLambdaArn(event.detail.value)}
                  />
                </FormField>
                {!isEditFlow && 
                  <FormField label="Type">
                    <Select
                      selectedOption={type}
                      onChange={({ detail }) => {
                        setType(detail.selectedOption);
                      }}
                      options={selectIntegrationEnum(IntegrationTypes, hasDatalandIntegration ? ["DATALAND"] : [] )}
                      loadingText="Loading instances"
                      placeholder="Choose a status"
                      disabled={disableFormFields}
                      empty="No status"
                    />
                  </FormField>
                }
              {!isEditFlow && type && type.label === IntegrationTypes.IOT_CORE && (
                <>
                  <FormField label="Topic">
                    <SpaceBetween size='s'>
                  {selectedVariables.length === 0 && <TextContent>Click on the buttons to select the variables</TextContent>}
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <div>{selectedButtons()}</div>
                    </div>

                    <Container
                      variant='stacked'
                      header={
                        <Header
                          variant="h3"
                          description="The topic is created using the variables and a custom string provided by you."
                        >
                          Customise Topic
                        </Header>
                      }
                    >
                    <div style={{display:'flex', flexDirection: 'column', gap: '2px'}}>
                      <Grid
                        gridDefinition={[
                          { colspan: { default: 3, xxs: 9 } },
                          { colspan: { default: 9, xxs: 3 } }
                        ]}
                      >
                        <Input
                          disabled={disableFormFields}
                          value={topicCustomText}
                          onChange={(e) => setTopicCustomText(e.detail.value)}
                        />
                        <Button
                          variant="primary"
                          onClick={(e) => {
                            setSelectedVariables([...selectedVariables, topicCustomText]);
                            setTopicCustomText('');
                            e.preventDefault();
                          }}>
                            Add To Topic
                        </Button>
                      </Grid>
                      <div>{allButtons} </div>
                    </div>
                    </Container>

                  </SpaceBetween>
                  <Box margin={{ vertical: "l" }} textAlign="center" color="inherit">
                    <Alert type='info' header='Topic Format'>
                      <TextContent>
                        <div>
                          <p>Topics are created using variable names. You can include as many variable names as needed. At the end, you finish the string with a custom topic name provided by you.</p>
                          <p><strong>Example:</strong></p>
                          <p>/$NAME/$TIME/$SERVER_FULL_TIME/TopicName</p>
                        </div>
                    </TextContent>
                    </Alert>
                  <Box margin={{ vertical: "l" }} textAlign="center" color="inherit">
                    <Table
                    renderAriaLive={({
                      firstIndex,
                      lastIndex,
                      totalItemsCount
                    }) =>
                      `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
                    }
                    ariaLabels={{
                      selectionGroupLabel: "Items selection",
                      allItemsSelectionLabel: () => "select all",
                      itemSelectionLabel: ({ selectedItems }, item) =>
                        item.name
                    }}
                    columnDefinitions={[
                      {
                        id: "variable",
                        header: "Variable name",
                        cell: item => item.name,
                        sortingField: "name"
                      },
                      {
                        id: "description",
                        header: "Description",
                        cell: item => item.description
                      }
                    ]}
                    items={topicVariables}
                    header={
                      <Header variant="h3"
                      >
                        Variables
                      </Header>
                    }
                  />
                  </Box>
                  </Box>
                  </FormField>
                </>
              )}
              {isEditFlow && 
                  <FormField label="Status">
                    <Select
                      selectedOption={statusIntegration}
                      onChange={({ detail }) => {
                        setStatusIntegration(detail.selectedOption);
                      }}
                      options={selectIntegrationEnum(IntegrationStatus)}
                      loadingText="Loading instances"
                      placeholder="Choose a status"
                      disabled={disableFormFields}
                      empty="No status"
                    />
                  </FormField>
                }
                {type.label === IntegrationTypes.HTTPS && 
                  <>
                    <FormField label="Host Name">
                      <Input
                        disabled={disableFormFields}
                        value={hostName}
                        onChange={(event) => setHostName(event.detail.value)}
                      />
                    </FormField>
                    <FormField label="Request Type">
                      <Select
                        selectedOption={requestType}
                        onChange={({ detail }) => {
                          setRequestType(detail.selectedOption);
                        }}
                        options={selectIntegrationEnum(RequestTypes)}
                        loadingText="Loading request types"
                        placeholder="Choose a request types"
                        disabled={disableFormFields}
                        empty="No request types"
                      />
                    </FormField>
                    <FormField label="Port">
                      <Input
                        type='number'
                        disabled={disableFormFields}
                        value={port}
                        onChange={(event) => setPort(event.detail.value)}
                      />
                    </FormField>
                    <FormField label="Path">
                      <Input
                        disabled={disableFormFields}
                        value={path}
                        onChange={(event) => setPath(event.detail.value)}
                      />
                    </FormField>
                    <FormField label="Username">
                      <Input
                        disabled={disableFormFields}
                        value={username}
                        onChange={(event) => setUserName(event.detail.value)}
                      />
                    </FormField>
                    <FormField label="Password">
                      <Input
                        disabled={disableFormFields}
                        value={password}
                        onChange={(event) => setPassword(event.detail.value)}
                      />
                    </FormField>
                    <FormField
                      label="Headers"
                      description="Enter stack name and each output value "
                    >
                      <AttributeEditor
                        onAddButtonClick={() => setHeaders([...headers, {}])}
                        onRemoveButtonClick={({ detail: { itemIndex } }) => {
                          const tmpItems = [...headers];
                          tmpItems.splice(itemIndex, 1);
                          setHeaders(tmpItems);
                        }}
                        items={headers}
                        addButtonText="Add new Header"
                        definition={[
                          {
                            label: 'Key',
                            control: (item: any, itemIndex) => (
                              <Control
                                prop="label"
                                value={item.label}
                                index={itemIndex}
                                setOutputItems={setHeaders}
                              />
                            ),
                          },
                          {
                            label: 'Value',
                            control: (item, itemIndex) => (
                              <Control
                                prop="value"
                                value={item.value}
                                index={itemIndex}
                                setOutputItems={setHeaders}
                              />
                            ),
                          },
                        ]}
                        removeButtonText="Remove"
                        empty="No items associated with the resource."
                      />
                    </FormField>
                    <FormField label="Private Key">
                      <Input
                        disabled={disableFormFields}
                        value={privateKey}
                        onChange={(event) => setPrivateKey(event.detail.value)}
                      />
                    </FormField>
                    <FormField label="Public Key">
                      <Input
                        disabled={disableFormFields}
                        value={publicKey}
                        onChange={(event) => setPublicKey(event.detail.value)}
                      />
                    </FormField>
                    <FormField label="Certificate">
                      <Input
                        disabled={disableFormFields}
                        value={ca}
                        onChange={(event) => setCa(event.detail.value)}
                      />
                    </FormField>
                    <FormField label="Timeout in ms">
                      <Input
                        type='number'
                        disabled={disableFormFields}
                        value={timeout}
                        onChange={(event) => setTimeout(event.detail.value)}
                      />
                    </FormField>
                  </>
                }
                  {type.label === IntegrationTypes.DEPLOYMENT && 
                  <FormField label="Deployments">
                    <Select
                      selectedOption={selectedDeployment}
                      onChange={({ detail }) => {
                        setSelectedDeployment(detail.selectedOption);
                      }}
                      options={deployments}
                      loadingText="Loading instances"
                      placeholder="Choose a deployment"
                      disabled={disableFormFields}
                      empty="No deployments"
                    />
                  </FormField>
                }
                {type.label === IntegrationTypes.KINESIS && 
                  <>
                    <FormField label="ARN">
                      <Input
                        disabled={disableFormFields}
                        value={kinesisArn}
                        onChange={(event) => setKinesisArn(event.detail.value)}
                      />
                    </FormField>
                    <FormField label="Application Role Arn">
                      <Input
                        disabled={disableFormFields}
                        value={applicationRoleArn}
                        onChange={(event) => setApplicationRoleArn(event.detail.value)}
                      />
                    </FormField>
                  </>
                }
              </>
            )}
        </SpaceBetween>
      </Form>
    </form>
  );
};

export default CreateIntegration;
