import {
  SpaceBetween,
  Button,
  Header,
  FormField,
  Input,
  Form,
  TokenGroup,
  AttributeEditor,
} from '@cloudscape-design/components';
import { catalogAPI } from 'api';
import Spinner from 'components/spinner/Spinner';
import {
  API_URL_PATH_LIST_TEMPLATES,
  URL_PATH_CATALOG_MANAGER,
  URL_PATH_CREATE_CATALOG_TEMPLATE,
} from 'constants/urls';
import useFetch from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { buildArrayForPayload, buildExpectedOutput, checkKeyDown } from 'utils';

const Control = React.memo(
  ({ value, index, setOutputItems, prop }: { value: any; index: any; setOutputItems: any; prop: string }) => {
    return (
      <Input
        value={value}
        onChange={({ detail }) => {
          setOutputItems((items: any) => {
            const updatedItems = [...items];
            updatedItems[index] = {
              ...updatedItems[index],
              [prop]: detail.value,
            };
            return updatedItems;
          });
        }}
      />
    );
  }
);

const CreateTemplate = () => {
  const [templateId, setTemplateId] = useState<string>('');
  const [friendlyName, setFriendlyName] = useState('');
  const [description, setDescription] = useState('');
  const [repo, setRepo] = useState('');
  const [outputItems, setOutputItems] = useState<any>([{}]);
  const [defaultBranch, setDefaultBranch] = useState('');
  const [envDisplayName, setenvDisplayName] = useState('');

  const [enterParameters, setEnterParameters] = useState('');
  const [requiredParameters, setRequiredParameters] = useState<any>([]);

  const [isEditFlow, setIsEditFlow] = useState(false);
  const [disableFormFields, setDisableFormFields] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    if (
      (location && location.state?.action === 'edit') ||
      (location.pathname && location.pathname.indexOf('edit') > -1)
    ) {
      setIsEditFlow(true);
    } else {
      setIsEditFlow(false);
    }
  }, []);

  const {
    loading: getTemplateLoading,
    error: getTemplateError,
    fetchData: getTemplate,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: `${API_URL_PATH_LIST_TEMPLATES}/${params?.id}`,
    },
    { manual: true }
  );

  const buildLabelValueArray = (arr: []) => {
    return arr.map((item: string) => {
      return {
        label: item,
        value: item,
      };
    });
  };

  useEffect(() => {
    if (isEditFlow) {
      getTemplate()
        .then((template) => {
          if (template?.data) {
            const { id, friendlyName, description, repo, requiredParameters, defaultBranch, enviornmentDisplayName } =
              template?.data;
            setTemplateId(id);
            setFriendlyName(friendlyName);
            setDescription(description);
            setRepo(repo);
            setDefaultBranch(defaultBranch);
            setenvDisplayName(envDisplayName);

            setRequiredParameters(() => {
              return buildLabelValueArray(requiredParameters);
            });
          } else {
            navigate(URL_PATH_CREATE_CATALOG_TEMPLATE);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isEditFlow]);

  const { status: updateTemplateStatus, fetchData: updateTemplate } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'PATCH',
      url: API_URL_PATH_LIST_TEMPLATES,
      param: templateId,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        friendlyName,
        description,
        repo,
        envDisplayName,
        defaultBranch,
        requiredParameters: buildArrayForPayload(requiredParameters),
      },
    },
    { manual: true }
  );

  const {
    error: createTemplateError,
    loading: createTemplateLoading,
    status: createTemplateStatus,
    fetchData: createTemplate,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'POST',
      url: API_URL_PATH_LIST_TEMPLATES,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        friendlyName,
        description,
        repo,
        envDisplayName,
        defaultBranch,
        requiredParameters: buildArrayForPayload(requiredParameters),
        expectedOutputs: buildExpectedOutput(outputItems),
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (createTemplateStatus === 201 || updateTemplateStatus === 200) {
      let stateObj = {};

      if (isEditFlow) {
        stateObj = {
          action: 'update-template',
          status: 'success',
          message: `Successfully updated template ${templateId}`,
          tab: 'templates',
        };
      } else {
        stateObj = {
          action: 'create-template',
          status: 'success',
          message: `Successfully created template ${templateId}`,
          tab: 'templates',
        };
      }

      navigate(URL_PATH_CATALOG_MANAGER, {
        state: stateObj,
      });
    } else if (createTemplateStatus > 201) {
      setDisableFormFields(false);
    }
  }, [createTemplateStatus, updateTemplateStatus]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableFormFields(true);

    if (isEditFlow) {
      updateTemplate();
    } else {
      createTemplate();
    }
  };

  return (
    <form onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="link" onClick={() => navigate(URL_PATH_CATALOG_MANAGER, {})}>
              Cancel
            </Button>
            <Button variant="primary" loading={createTemplateLoading}>
              Submit
            </Button>
          </SpaceBetween>
        }
        header={
          <Header variant="h2" description="">
            {isEditFlow ? 'Update' : 'Create'} a Template
          </Header>
        }
        errorText={createTemplateError || getTemplateError}
      >
        <SpaceBetween direction="vertical" size="l">
          {getTemplateLoading ? (
            <Spinner />
          ) : (
            <>
              <FormField label="Friendly name">
                <Input
                  disabled={disableFormFields}
                  value={friendlyName}
                  onChange={(event) => setFriendlyName(event.detail.value)}
                />
              </FormField>
              <FormField label="Description">
                <Input
                  disabled={disableFormFields}
                  value={description}
                  onChange={(event) => setDescription(event.detail.value)}
                />
              </FormField>

              <FormField label="Repository">
                <Input disabled={disableFormFields} value={repo} onChange={(event) => setRepo(event.detail.value)} />
              </FormField>

              <FormField label="Default Branch">
                <Input
                  disabled={disableFormFields}
                  value={defaultBranch}
                  onChange={(event) => setDefaultBranch(event.detail.value)}
                />
              </FormField>

              <FormField label="Environment display name">
                <Input
                  disabled={disableFormFields}
                  value={envDisplayName}
                  onChange={(event) => setenvDisplayName(event.detail.value)}
                />
              </FormField>

              <FormField label="Required Parameters" description="Enter each required parameter and press ENTER key">
                <Input
                  disabled={disableFormFields}
                  value={enterParameters}
                  onKeyDown={(event) => {
                    if (event.detail.key === 'Enter') {
                      setRequiredParameters([...requiredParameters, { label: enterParameters }]);
                      setEnterParameters('');
                    }
                  }}
                  onChange={(event) => setEnterParameters(event.detail.value)}
                />
                <TokenGroup
                  onDismiss={({ detail: { itemIndex } }) => {
                    setRequiredParameters([
                      ...requiredParameters.slice(0, itemIndex),
                      ...requiredParameters.slice(itemIndex + 1),
                    ]);
                  }}
                  items={requiredParameters}
                />
              </FormField>

              {!isEditFlow && (
                <>
                  <FormField
                    label="Expected Outputs"
                    description="Enter stack name and separate each output value with a comma"
                  >
                    <AttributeEditor
                      onAddButtonClick={() => setOutputItems([...outputItems, {}])}
                      onRemoveButtonClick={({ detail: { itemIndex } }) => {
                        const tmpItems = [...outputItems];
                        tmpItems.splice(itemIndex, 1);
                        setOutputItems(tmpItems);
                      }}
                      items={outputItems}
                      addButtonText="Add new stack"
                      definition={[
                        {
                          label: 'Stack Name',
                          control: (item: any, itemIndex) => (
                            <Control
                              prop="label"
                              value={item.label}
                              index={itemIndex}
                              setOutputItems={setOutputItems}
                            />
                          ),
                        },
                        {
                          label: 'Output Values',
                          control: (item, itemIndex) => (
                            <Control
                              prop="value"
                              value={item.value}
                              index={itemIndex}
                              setOutputItems={setOutputItems}
                            />
                          ),
                        },
                      ]}
                      removeButtonText="Remove"
                      empty="No items associated with the resource."
                    />
                  </FormField>
                </>
              )}
            </>
          )}
        </SpaceBetween>
      </Form>
    </form>
  );
};

export default CreateTemplate;
