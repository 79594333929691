import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext({
  user: '',
  groups: '',
  token: '',
  logout: () => { },
  error: '',
  loading: true,
});

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState('');
  const [token, setToken] = useState('');
  const [groups, setGroups] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user?.attributes?.nickname);
        setToken(user?.signInUserSession?.idToken?.jwtToken);

        const decodedJWT: any = user && jwt_decode(user?.signInUserSession?.idToken?.jwtToken);

        const getGroups = decodedJWT ? decodedJWT['custom:adgroups'] : '';
        setGroups(getGroups);
      })
      .catch((error) => {
        console.error(error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      if (event === 'customOAuthState') {
        const routeData = decodeURIComponent(data);
        if (routeData) {
          navigate(routeData);
        } else {
          navigate('/');
        }
      }
    });
  }, []);

  const logout = () => {
    try {
      Auth.signOut().then(() => {

      });
    } catch (error) {
      console.error(error);
    }
  };

  const contextValue = useMemo(
    () => ({
      user,
      groups,
      token,
      logout,
      error,
      loading,
    }),
    [user, groups, token, error, loading]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthContext;
