//API url
export const API_URL_PATH_PHYSICAL_DEVICE = '/physical-device';
export const API_URL_PATH_LIST_MQTT_CLIENT = '/mqtt-client/physicaldevice';
export const API_URL_PATH_CREATE_MQTT_CLIENT = '/mqtt-client';
export const API_URL_PATH_CREATE_TAG = '/tag';

export const API_URL_PATH_DEVICE_TYPE = '/device-type';

export const API_URL_PATH_DEVICE = '/device';
export const API_URL_PATH_BULK_DEVICE = '/device/bulk';

// Applications
export const API_URL_PATH_LIST_APPLICATIONS = '/application';
export const API_URL_PATH_LIST_SHOPPING_APPLICATIONS = '/shopping/application';

// Integration
export const API_URL_PATH_LIST_INTEGRATION = '/integration';

// Deployments
export const API_URL_PATH_LIST_DEPLOYMENTS = '/deployment';
export const API_URL_PATH_LIST_SHOPPING_DEPLOYMENTS = '/shopping/deployment';

// Templates
export const API_URL_PATH_LIST_TEMPLATES = '/template';
export const API_URL_PATH_UPLOAD_TEMPLATE_IMAGE = '/template/image';
export const API_URL_PATH_UPLOAD_TEMPLATE_CATALOG = '/template/catalog';
export const API_URL_PATH_LIST_SHOPPING_TEMPLATES = '/shopping/template';

// AWS Accounts
export const API_URL_PATH_LIST_ACCOUNTS = '/account-pool';
export const API_URL_PATH_LIST_CATALOG_ACCOUNTS = '/account-pool?state=registered';
export const API_URL_PATH_LIST_SHOPPING_ACCOUNTS = '/shopping/accounts';

//App url paths
//Login
export const URL_PATH_LOGIN = 'login';

//Unauthorized
export const URL_PATH_UNAUTHORIZED = 'unauthorized';

//Physical device manager paths
export const URL_PATH_PHYSICAL_DEVICE = 'lns';
export const URL_FULL_PATH_LIST_PHYSICAL_DEVICE = '/lns/list';
export const URL_PATH_CREATE_PHYSICAL_DEVICE = 'create';
export const URL_PATH_EDIT_PHYSICAL_DEVICE = 'edit/:physicaldevice';

export const URL_PATH_CREATE_MQTT_CLIENT = 'create-mqtt/:physicaldevice';

export const URL_PATH_CREATE_TAG = 'create-tag';

//Device type manager paths
export const URL_PATH_DEVICE_TYPE = '/device-type';
export const URL_FULL_PATH_LIST_DEVICE_TYPE = '/device-type/list';
export const URL_PATH_CREATE_DEVICE_TYPE = 'create';
export const URL_PATH_EDIT_DEVICE_TYPE = 'update';
export const URL_FULL_PATH_EDIT_DEVICE_TYPE = '/device-type/update';

//Device manager paths
export const URL_PATH_DEVICE = '/device';
export const URL_FULL_PATH_LIST_DEVICE = '/device/list';
export const URL_PATH_CREATE_DEVICE = 'create';
export const URL_PATH_INSTALL_DEVICE = 'install';

//Catalog manager paths
export const URL_PATH_CATALOG_ADMIN = '/catalog/admin';
export const URL_PATH_CATALOG_MANAGER = '/catalog/admin/manager';
export const URL_PATH_CATALOG_USER = '/catalog/user';
export const URL_PATH_CATALOG_SHOPPING = '/catalog/user/shopping';

// Catalog manager create/update application
export const URL_PATH_CREATE_CATALOG_APPLICATION = '/catalog/admin/application/create';
export const URL_PATH_UPDATE_CATALOG_APPLICATION = '/catalog/admin/application/edit/:id';

// Catalog manager create/update integration
export const URL_PATH_CREATE_CATALOG_INTEGRATION = '/catalog/admin/integration/create';
export const URL_PATH_UPDATE_CATALOG_INTEGRATION = '/catalog/admin/integration/edit/:id';
export const URL_PATH_DUPLICATE_EDIT_CATALOG_INTEGRATION = '/catalog/admin/integration/duplicate/:id';

// Catalog shopping create/update deployment
export const URL_PATH_UPDATE_CATALOG_SHOPPING_APPLICATION = '/catalog/user/deployment/edit/:id';

// Catalog manager create/update deployment
export const URL_PATH_CREATE_CATALOG_DEPLOYMENT = '/catalog/admin/deployment/create';
export const URL_PATH_UPDATE_CATALOG_DEPLOYMENT = '/catalog/admin/deployment/edit/:iotApplicationId';

// Catalog shopping create/update deployment
export const URL_PATH_UPDATE_CATALOG_SHOPPING_DEPLOYMENT = '/catalog/user/deployment/edit/:iotApplicationId';

export const URL_PATH_CREATE_CATALOG_TEMPLATE = '/catalog/admin/template/create';
export const URL_PATH_UPDATE_CATALOG_TEMPLATE = '/catalog/admin/template/edit/:id';

// Locations
export const API_URL_UM_RESOURCE_REGIONS = '/location';
export const API_URL_UM_RESOURCE_COUNTRIES = '/location/region';
export const API_URL_UM_RESOURCE_SITES = '/location/country';
export const API_URL_UM_RESOURCE_BUILDINGS = '/location/site';
export const API_URL_UM_RESOURCE_FLOORS = '/location/building';
export const API_URL_UM_RESOURCE_ROOMS = '/location/floor';
