import { Container } from '@cloudscape-design/components';

import CreatePhysicalDevice from 'components/physical-devices/CreatePhysicalDevice';

const CreatePhysicalDevicesPage = () => {
  return (
    <Container>
      <CreatePhysicalDevice />
    </Container>
  );
};

export default CreatePhysicalDevicesPage;
