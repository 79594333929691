import { createContext, useContext, useEffect, useState } from 'react';
import { ColumnLayout } from '@cloudscape-design/components';
import { useLocation, useNavigate } from 'react-router-dom';

import DeviceTypesTable from 'components/device-type-manager/DeviceTypesTable';
import DeviceTypesTabs from 'components/device-type-manager/DeviceTypesTabs';
import { usePageLayoutContext } from 'components/common/layout';
import { API_URL_PATH_DEVICE_TYPE } from 'constants/urls';
import { DeviceTypesListProps } from 'types/custom';
import { extractFilteringAttributes } from 'utils';
import Spinner from 'components/spinner/Spinner';
import { platformCoreAPI } from 'api';
import useFetchWithReactQuery from 'hooks/useFetchWithReactQuery';

const DeviceTypesContext = createContext({
  allItems: [] as any[],
  setAllItems: (state: DeviceTypesListProps[]) => { },
  loading: false,
  error: '',
  setSelectedDeviceType: (state: DeviceTypesListProps[]) => { },
  selectedDeviceType: [] as DeviceTypesListProps[] | undefined,
  refetch: () => { },
});

export const useDeviceTypesContext = () => useContext(DeviceTypesContext);

const ListDeviceTypesPage = () => {
  const [allDevicesTypes, setAllDevicesTypes] = useState<DeviceTypesListProps[]>([]);
  const [selectedDeviceType, setSelectedDeviceType] = useState<DeviceTypesListProps[]>([]);

  const { setNotification } = usePageLayoutContext();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.action) {
      setNotification([
        {
          type: 'success',
          content: location.state.message,
        },
      ]);
    }

    navigate(location.pathname, {});
  }, []);

  const { data, status, error, isLoading, refetch } = useFetchWithReactQuery<{ results: [] }>({
    axiosInstance: platformCoreAPI,
    key: 'deviceTypes',
    url: API_URL_PATH_DEVICE_TYPE,
  });

  useEffect(() => {
    if (status === 'success') {
      const parsedDeviceTypes = extractFilteringAttributes(data.results);
      setAllDevicesTypes(parsedDeviceTypes);
      if (selectedDeviceType) {
        const selected = parsedDeviceTypes.find((item) => item.name === selectedDeviceType[0]?.name);
        if (selected) setSelectedDeviceType([selected]);
      }
    }
  }, [status, data]);

  return (
    <DeviceTypesContext.Provider
      value={{
        loading: isLoading,
        allItems: allDevicesTypes,
        setAllItems: setAllDevicesTypes,
        error: error?.message || '',
        setSelectedDeviceType,
        selectedDeviceType,
        refetch,
      }}
    >
      <ColumnLayout variant="text-grid">
        {!data && isLoading && (
          <>
            <br></br>
            <Spinner />
          </>
        )}
        {data && <DeviceTypesTable />}
      </ColumnLayout>

      {allDevicesTypes.length > 0 && selectedDeviceType![0]?.name !== undefined && (
        <>
          <ColumnLayout variant="text-grid">
            <DeviceTypesTabs />
          </ColumnLayout>
        </>
      )}
    </DeviceTypesContext.Provider>
  );
};

export default ListDeviceTypesPage;
