import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, SpaceBetween, Button, Header, FormField, Input } from '@cloudscape-design/components';
import useFetch from 'hooks/useFetch';
import { platformCoreAPI } from 'api';

const CreatePhysicalDevice = () => {
  const [id, setId] = useState('');
  const [friendlyName, setFriendlyName] = useState('');
  const [description, setDescription] = useState('');
  const [host, setHost] = useState('');
  const [credentialUserId, setCredentialUserId] = useState('');
  const [credentialUserPassword, setCredentialUserPassword] = useState('');
  const [physicaldevicetype, setPhysicaldevicetype] = useState('');
  const [disableFormFields, setDisableFormFields] = useState(false);

  const navigate = useNavigate();

  const { error, loading, status, fetchData } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'POST',
      url: '/physical-device',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        id,
        friendlyName,
        description,
        host,
        credentialUserId,
        credentialUserPassword,
        physicaldevicetype,
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (status === 201) {
      navigate('/lns/list', {
        state: {
          action: 'create-physical-device',
          status: 'success',
          message: `Successfully created physical device ${friendlyName}`,
        },
      });
    } else if (status > 201) {
      setDisableFormFields(false);
    }
  }, [status]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableFormFields(true);
    fetchData();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="link" onClick={() => navigate('/lns/list', {})}>
              Cancel
            </Button>
            <Button variant="primary" loading={loading}>
              Submit
            </Button>
          </SpaceBetween>
        }
        header={
          <Header variant="h2" description="">
            Create Physical Device
          </Header>
        }
        errorText={error}
      >
        <SpaceBetween direction="vertical" size="l">
          <FormField label="Physical Device ID">
            <Input disabled={disableFormFields} value={id} onChange={(event) => setId(event.detail.value)} />
          </FormField>
          <FormField label="Friendly name">
            <Input
              disabled={disableFormFields}
              value={friendlyName}
              onChange={(event) => setFriendlyName(event.detail.value)}
            />
          </FormField>
          <FormField label="Description">
            <Input
              disabled={disableFormFields}
              value={description}
              onChange={(event) => setDescription(event.detail.value)}
            />
          </FormField>
          <FormField
            label={
              <span>
                Host <i>- optional</i>{' '}
              </span>
            }
          >
            <Input disabled={disableFormFields} value={host} onChange={(event) => setHost(event.detail.value)} />
          </FormField>
          <FormField
            label={
              <span>
                Credential User Id <i>- optional</i>{' '}
              </span>
            }
          >
            <Input
              disabled={disableFormFields}
              value={credentialUserId}
              onChange={(event) => setCredentialUserId(event.detail.value)}
            />
          </FormField>
          <FormField
            label={
              <span>
                Credential User Password <i>- optional</i>{' '}
              </span>
            }
          >
            <Input
              disabled={disableFormFields}
              type="password"
              value={credentialUserPassword}
              onChange={(event) => setCredentialUserPassword(event.detail.value)}
            />
          </FormField>
          <FormField label="Physical Device Type">
            <Input
              disabled={disableFormFields}
              value={physicaldevicetype}
              onChange={(event) => setPhysicaldevicetype(event.detail.value)}
            />
          </FormField>
        </SpaceBetween>
      </Form>
    </form>
  );
};

export default CreatePhysicalDevice;
