import { SpaceBetween, Button, Header, FormField, Input, Form, Select, Toggle } from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { platformCoreAPI } from 'api';
import { API_URL_PATH_DEVICE, URL_FULL_PATH_LIST_DEVICE } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dropdownFromProps, selectFewerProps } from 'utils';

const CreateDevice = () => {
  const [lookupId, setLookupId] = useState('');
  const [friendlyName, setFriendlyName] = useState('');
  const [description, setDescription] = useState('');
  const [invoiceId, setInvoiceId] = useState('');
  const [selectedPhysicalDeviceId, setSelectedPhysicalDeviceId] = useState<OptionDefinition | null>(null);
  const [deviceEUI, setDeviceEUI] = useState('');
  const [appEUI, setAppEUI] = useState('');
  const [selectedDeviceType, setSelectedDeviceType] = useState<OptionDefinition | null>(null);
  const [selectedDeviceGroup, setSelectedDeviceGroup] = useState<OptionDefinition | null>(null);
  const [inactivityTimeout, setInactivityTimeout] = useState('3600');
  const [useAppNetworkSettings, setUseAppNetworkSettings] = useState(false);
  const [appKey, setAppKey] = useState('');
  const [disableFormFields, setDisableFormFields] = useState(false);

  const [deviceTypeIdList, setDeviceTypeIdList] = useState<any>([]);
  const [physicalDeviceList, setPhysicalDeviceList] = useState<any>([]);
  const [deviceGroups, setDeviceGroups] = useState<any>([]);

  const navigate = useNavigate();

  const {
    response: deviceTypeIdResponse,
    loading: deviceTypeIdLoading,
    error: deviceTypeIdError,
  } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'GET',
      url: `/device-type`,
    },
    { manual: false }
  );

  const {
    response: physicalDeviceResponse,
    loading: physicalDeviceLoading,
    error: physicalDeviceError,
  } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'GET',
      url: `/physical-device`,
    },
    { manual: false }
  );

  const {
    response: deviceGroupsResponse,
    loading: deviceGroupsLoading,
    error: deviceGroupsError,
    fetchData: fetchDeviceGroups,
  } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'GET',
      url: `/mqtt-client/tektelic/physicaldevice/${selectedPhysicalDeviceId?.value}/devicegroups`,
    },
    { manual: true }
  );

  useEffect(() => {
    if (deviceTypeIdResponse) {
      const deviceTypeIdList = deviceTypeIdResponse?.results?.map(dropdownFromProps);
      setDeviceTypeIdList(deviceTypeIdList);
    }
  }, [deviceTypeIdResponse]);

  useEffect(() => {
    if (physicalDeviceResponse) {
      const physicalDeviceList = physicalDeviceResponse?.results?.map(dropdownFromProps);
      setPhysicalDeviceList(physicalDeviceList);
    }
  }, [physicalDeviceResponse]);

  useEffect(() => {
    if (deviceGroupsResponse && Array.isArray(deviceGroupsResponse)) {
      const deviceGroupsList = deviceGroupsResponse?.map(selectFewerProps);
      setDeviceGroups(deviceGroupsList);
    }
  }, [deviceGroupsResponse]);

  useEffect(() => {
    if (selectedPhysicalDeviceId) {
      fetchDeviceGroups();
    }
  }, [fetchDeviceGroups, selectedPhysicalDeviceId]);

  const { error, loading, status, fetchData } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'POST',
      url: API_URL_PATH_DEVICE,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        friendlyName,
        lookupId,
        description,
        invoiceId,
        physicalDeviceId: selectedPhysicalDeviceId?.value,
        deviceEUI,
        appEUI,
        deviceTypeId: selectedDeviceType?.value,
        deviceGroupId: selectedDeviceGroup?.value,
        inactivityTimeout: parseInt(inactivityTimeout) || 3600,
        useAppNetworkSettings,
        appKey,
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (status === 201) {
      navigate(URL_FULL_PATH_LIST_DEVICE, {
        state: {
          action: 'create-device-type',
          status: 'success',
          message: `Successfully created device ${friendlyName}`,
        },
      });
    } else if (status > 201) {
      setDisableFormFields(false);
    }
  }, [status]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableFormFields(true);
    fetchData();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="link" onClick={() => navigate(URL_FULL_PATH_LIST_DEVICE, {})}>
              Cancel
            </Button>
            <Button variant="primary" loading={loading}>
              Submit
            </Button>
          </SpaceBetween>
        }
        header={
          <Header variant="h2" description="">
            Create a Device
          </Header>
        }
        errorText={error}
      >
        <SpaceBetween direction="vertical" size="l">
          <FormField
            label={
              <span>
                Friendly name <i>- optional</i>{' '}
              </span>
            }
          >
            <Input
              disabled={disableFormFields}
              value={friendlyName}
              onChange={(event) => setFriendlyName(event.detail.value)}
            />
          </FormField>
          <FormField
            label={
              <span>
                Description <i>- optional</i>{' '}
              </span>
            }
          >
            <Input
              disabled={disableFormFields}
              value={description}
              onChange={(event) => setDescription(event.detail.value)}
            />
          </FormField>
          <FormField label="Invoice ID">
            <Input
              disabled={disableFormFields}
              value={invoiceId}
              onChange={(event) => setInvoiceId(event.detail.value)}
            />
          </FormField>
          <FormField label="Lookup ID">
            <Input
              disabled={disableFormFields}
              value={lookupId}
              onChange={(event) => setLookupId(event.detail.value)}
            />
          </FormField>
          <FormField label="Device EUI">
            <Input
              disabled={disableFormFields}
              value={deviceEUI}
              onChange={(event) => setDeviceEUI(event.detail.value)}
            />
          </FormField>
          <FormField label="App EUI">
            <Input disabled={disableFormFields} value={appEUI} onChange={(event) => setAppEUI(event.detail.value)} />
          </FormField>
          <FormField label="Device Type">
            <Select
              selectedOption={selectedDeviceType}
              onChange={({ detail }) => setSelectedDeviceType(detail.selectedOption)}
              options={deviceTypeIdList}
              loadingText="Loading"
              placeholder="Choose a device type"
              statusType={deviceTypeIdLoading ? 'loading' : 'finished'}
              disabled={disableFormFields}
              errorText={deviceTypeIdError}
              empty="No device types"
            />
          </FormField>
          <FormField label="Physical Device Type">
            <Select
              selectedOption={selectedPhysicalDeviceId}
              onChange={({ detail }) => {
                setSelectedDeviceGroup(null);
                setSelectedPhysicalDeviceId(detail.selectedOption);
              }}
              options={physicalDeviceList}
              loadingText="Loading"
              placeholder="Choose a physical device type"
              statusType={physicalDeviceLoading ? 'loading' : 'finished'}
              disabled={disableFormFields}
              errorText={physicalDeviceError}
              empty="No device types"
            />
          </FormField>
          <FormField label="Device Group">
            <Select
              selectedOption={selectedDeviceGroup}
              onChange={({ detail }) => setSelectedDeviceGroup(detail.selectedOption)}
              options={deviceGroups}
              loadingText="Loading"
              placeholder="Choose a device group"
              statusType={deviceGroupsLoading ? 'loading' : 'finished'}
              disabled={!selectedPhysicalDeviceId?.label || disableFormFields}
              errorText={deviceGroupsError}
              empty="No device groups"
            />
          </FormField>
          <FormField label="Inactivity Timeout">
            <Input
              type='number'
              value={inactivityTimeout}
              onChange={(event) => setInactivityTimeout(event.detail.value)}
              disabled={disableFormFields}
            />
          </FormField>
          <FormField label="Use App Network Settings">
            <Toggle
              checked={useAppNetworkSettings}
              onChange={() => setUseAppNetworkSettings(!useAppNetworkSettings)}
              disabled={disableFormFields}
            >{useAppNetworkSettings ? 'Enabled' : 'Disabled'}</Toggle>
          </FormField>
          <FormField label="App Key">
            <Input disabled={disableFormFields} value={appKey} onChange={(event) => setAppKey(event.detail.value)} />
          </FormField>
          {/* TODO: Create fields for the following, which are already defined in the backend (ensure they work first):
            devAddress: string;
            eventThresholdDownlinkQueueSize: number;
            eventThresholdUlPerMinuteCounter: number;
            eventThresholdJoinRequestWithoutUl: number;
            abp: boolean;
        */}
        </SpaceBetween>
      </Form>
    </form>
  );
};

export default CreateDevice;
