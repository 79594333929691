import { createContext, useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { AppLayout, Flashbar, FlashbarProps } from '@cloudscape-design/components';

import TopNav from 'components/common/topnav';
import SideNav from '../sidenav';

type NotificationWithId = Omit<FlashbarProps.MessageDefinition, 'dismissible' | 'dismissLabel' | 'onDismiss'>
export type Notification = Omit<NotificationWithId, 'id'>

const PageLayoutContext = createContext({
  setNotification: (state: Notification[]) => { },
  setActiveSideNav: (state: string) => { },
  activeSideNav: '',
});

export const usePageLayoutContext = () => useContext(PageLayoutContext);

const Layout = () => {
  const [notification, setNotification] = useState<NotificationWithId[]>([]);
  const [activeSideNav, setActiveSideNav] = useState('');

  return (
    <PageLayoutContext.Provider
      value={{
        setNotification: (newItems: Notification[]) => {
          // Group existing notifications with new ones.
          // Then set to distinct ids to ensure flash bar works well, overriding ids.
          setNotification(notifications => [...notifications, ...newItems].map((n, i) => ({ ...n, id: `n-${i}` })));
        },
        setActiveSideNav,
        activeSideNav,
      }}
    >
      <TopNav />
      <AppLayout
        toolsHide
        headerVariant='high-contrast'
        navigation={<SideNav />}
        stickyNotifications
        content={<Outlet />}
        notifications={<Flashbar items={notification.map((n) => ({
          ...n,
          type: n.type || 'error',
          content: n.content || 'An error occurred',
          dismissible: true,
          dismissLabel: 'Dismiss notification',
          onDismiss: () => setNotification(notifications => notifications.filter(item => item.id !== n.id)),
        }) as FlashbarProps.MessageDefinition).reverse()} stackItems={true} />}
        disableContentPaddings
      ></AppLayout>
    </PageLayoutContext.Provider>
  );
};

export default Layout;