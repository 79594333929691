import validator from "validator";
import { useFormik } from "formik";
import { platformCoreAPI } from "api";
import { Modal, Box, SpaceBetween, Button, Form, FormField, Input, Checkbox } from "@cloudscape-design/components";

import { usePageLayoutContext } from "components/common/layout";
import useMutationWithReactQuery from "hooks/useMutationWithReactQuery";
import { useDeviceTypesContext } from "pages/device-type-manager/ListDeviceTypesPage";
import { DeviceTypeResponse, Downlink } from "types/custom";

type CreateEditDownlinkModalProps = {
  selectedDownlink: Downlink;
  isEditing: boolean;
  isOpen: boolean;
  onClose: () => void;
};

const CreateEditDownlinkModal = ({
  selectedDownlink,
  isEditing,
  isOpen,
  onClose,
}: CreateEditDownlinkModalProps) => {
  const { selectedDeviceType, refetch } = useDeviceTypesContext();
  const { setNotification } = usePageLayoutContext();

  const { values, resetForm, handleSubmit, setFieldValue, isSubmitting, errors: formErrors } = useFormik<Downlink>({
    initialValues: {
      name: isEditing ? selectedDownlink.name : '',
      description: isEditing ? selectedDownlink.description : '',
      data: isEditing ? selectedDownlink.data : '',
      port: isEditing ? selectedDownlink.port : 0,
      confirmed: isEditing ? selectedDownlink.confirmed : false,
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors: { name?: string, data?: string, port?: string } = {};
      if (!values.name) {
        errors.name = 'Name is required';
      }
      if (!values.data) {
        errors.data = 'Data is required';
      }
      if (!validator.isBase64(values.data)) {
        errors.data = 'Data must be base64 encoded';
      }
      if (values.port < 0) {
        errors.port = 'Port must be a positive number';
      }

      return errors;
    },
    async onSubmit(values) {
      if (selectedDeviceType === undefined) return;

      let updatedDownlinks = [];

      if (isEditing) {
        updatedDownlinks = (selectedDeviceType?.[0]?.downlinks || []).map((downlink) =>
          downlink.name === selectedDownlink.name ? values : downlink
        );
      } else {
        updatedDownlinks = [...(selectedDeviceType?.[0]?.downlinks || []), values];
      }

      await updateDeviceType({
        downlinks: updatedDownlinks,
      });

      resetForm({
        errors: {},
        values: {
          name: '',
          description: '',
          data: '',
          port: 0,
          confirmed: false,
        }
      });
    },
  });

  console.log(formErrors);

  const { mutateAsync: updateDeviceType } = useMutationWithReactQuery<DeviceTypeResponse, { downlinks: Downlink[] }>({
    url: `${platformCoreAPI.defaults.baseURL}/device-type/${selectedDeviceType?.[0].name}`,
    method: 'PATCH',
    api: platformCoreAPI,
    onSuccess: () => {
      setNotification([
        {
          type: 'success',
          content: `Successfully ${isEditing ? 'updated' : 'created'} downlink`,
        },
      ]);

      refetch();
      onClose();
    },
    onError: (err) => {
      setNotification([
        {
          type: 'error',
          content: err?.message || `Something went wrong while ${isEditing ? 'editing' : 'creating'} downlink`,
        }]);

      onClose();
    },
  });

  const closeModalHandler = () => {
    resetForm({
      errors: {},
      values: {
        name: '',
        description: '',
        data: '',
        port: 0,
        confirmed: false,
      }
    });
    onClose();
  }

  return (
    <Modal
      onDismiss={closeModalHandler}
      visible={isOpen}
      closeAriaLabel="Close modal"
      header={`${isEditing ? 'Edit' : 'Create'} Downlink`}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={closeModalHandler}>
              Cancel
            </Button>
            <Button variant="primary" loading={isSubmitting} onClick={() => {
              handleSubmit();
            }}>
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Form>
        <SpaceBetween direction="vertical" size="l">
          <FormField label={<span>Name</span>}>
            {formErrors.name && <span style={{ color: 'red' }}>{formErrors.name}</span>}
            <Input
              value={values.name}
              onChange={({ detail }) => setFieldValue('name', detail.value)}
            />
          </FormField>

          <FormField label={<span>Description</span>}>
            <Input
              value={values.description}
              onChange={({ detail }) => setFieldValue('description', detail.value)}
            />
          </FormField>

          <FormField label={<span>Data</span>}>
            {formErrors.data && <span style={{ color: 'red' }}>{formErrors.data}</span>}
            <Input
              value={values.data}
              onChange={({ detail }) => setFieldValue('data', detail.value)}
            />
          </FormField>

          <FormField label={<span>Port</span>}>
            {formErrors.port && <span style={{ color: 'red' }}>{formErrors.port}</span>}
            <Input
              type="number"
              value={values.port.toString()}
              onChange={({ detail }) => setFieldValue('port', detail.value)}
            />
          </FormField>

          <FormField label={<span>Confirmed</span>}>
            <Checkbox
              checked={values.confirmed}
              onChange={({ detail }) => setFieldValue('confirmed', detail.checked)}
            />
          </FormField>
        </SpaceBetween>
      </Form>
    </Modal>
  );
};

export default CreateEditDownlinkModal;
