import {
  Alert,
  Button,
  Container,
  ContentLayout,
  Grid,
  Header,
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

import colors from '../../theme/colors';

const Unauthorized = () => {
  const navigate = useNavigate();
  const goBack = () => {
      navigate(-2);
  };

  return (
    <ContentLayout defaultPadding headerBackgroundStyle={colors.accentGreen2} header={<Header variant="h1">Unauthorized</Header>}>
      <Grid gridDefinition={[{ colspan: 12 }]}>
        <Container>
          <Alert type="error" action={<Button onClick={goBack}>Go back</Button>}>
            You do not have access to the this page.
          </Alert>
        </Container>
      </Grid>
    </ContentLayout>
  );
};

export default Unauthorized;
