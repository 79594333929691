import { ContentLayout, Header, Grid } from '@cloudscape-design/components';
import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';

import { usePageLayoutContext } from 'components/common/layout';
import colors from 'theme/colors';

const DeviceTypeManager = () => {
  const { setActiveSideNav } = usePageLayoutContext();
  useEffect(() => {
    setActiveSideNav(window.location.pathname);
  }, [setActiveSideNav]);

  return (
    <ContentLayout defaultPadding headerBackgroundStyle={colors.accentGreen2} header={<Header variant="h1">Device Type Manager</Header>}>
      <Grid gridDefinition={[{ colspan: 12 }]}>
        <Outlet />
      </Grid>
    </ContentLayout>
  );
};

export default DeviceTypeManager;
