import { Container } from '@cloudscape-design/components';
import CreateMQTTClient from 'components/physical-devices/CreateMqttClient';

const CreateMqttClientPage = () => {
  return (
    <Container>
      <CreateMQTTClient />
    </Container>
  );
};

export default CreateMqttClientPage;
