import { useCollection } from '@cloudscape-design/collection-hooks';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  Button,
  Table,
  TextFilter,
  Header,
  SpaceBetween,
  ButtonDropdown,
  Pagination,
} from '@cloudscape-design/components';

import { API_URL_PATH_DEVICE_TYPE, URL_FULL_PATH_EDIT_DEVICE_TYPE } from 'constants/urls';
import { useDeviceTypesContext } from 'pages/device-type-manager/ListDeviceTypesPage';
import { usePageLayoutContext } from 'components/common/layout';
import { EmptyState } from 'components/empty-state/EmptyState';
import PublishDeviceTypeModal from '../PublishDeviceTypeModal';
import { COLUMN_DEFINITIONS } from './table.config';
import DeleteModal from 'components/delete-modal';
import { getMatchesCountText } from 'utils';
import useFetch from 'hooks/useFetch';
import { platformCoreAPI } from 'api';

const DeviceTypesTable = () => {
  const [showPublishDeviceModal, setShowPublishDeviceModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const onDeleteDiscard = () => setShowDeleteModal(false);
  const navigate = useNavigate();
  const { allItems, loading, setSelectedDeviceType, selectedDeviceType, refetch } = useDeviceTypesContext();
  const { setNotification } = usePageLayoutContext();

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: (
          <EmptyState
            title="No device types"
            subtitle="No device types to display."
            action={
              <Button
                onClick={() =>
                  navigate('/device-type/create', {
                    state: {
                      action: 'create',
                    },
                  })
                }
              >
                Create a device type
              </Button>
            }
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: 10 },
      sorting: {},
    }
  );

  useEffect(() => {
    if (!selectedDeviceType![0]?.name) {
      setSelectedDeviceType([allItems.length > 0 && allItems[0]?.name ? allItems[0] : []]);
    }
  }, [allItems]);

  const {
    status: deleteDeviceTypeStatus,
    fetchData: deleteDeviceType,
    error: deleteDeviceTypeError,
  } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'DELETE',
      url: API_URL_PATH_DEVICE_TYPE,
      param: selectedDeviceType?.length ? selectedDeviceType![0]?.name : '',
    },
    { manual: true }
  );

  useEffect(() => {
    if (deleteDeviceTypeStatus === 200) {
      refetch();
      setNotification([
        {
          type: 'success',
          content: `Deleted device type ${selectedDeviceType![0]?.name} successfully`,
        },
      ]);

      setSelectedDeviceType([allItems.length > 0 && allItems[0]?.name ? allItems[1] : []]);
    } else if (deleteDeviceTypeStatus > 200) {
      setNotification([
        {
          type: 'error',
          content: deleteDeviceTypeError,
        },
      ]);
    }
  }, [deleteDeviceTypeStatus]);

  const handleButtonDropdownClick = (event: any) => {
    event.preventDefault();
    if (event.detail.id === 'device-type-delete') {
      setShowDeleteModal(true);
    } else if (event.detail.id === 'device-type-publish') {
      setShowPublishDeviceModal(true);
    } else if (event.detail.id === 'device-type-edit') {
      navigate(`${URL_FULL_PATH_EDIT_DEVICE_TYPE}/${selectedDeviceType![0].name}`);
    }
  };

  const onDeleteConfirm = () => {
    deleteDeviceType();
    setShowDeleteModal(false);
  };

  return (
    <>
      {showPublishDeviceModal && (
        <PublishDeviceTypeModal
          deviceType={selectedDeviceType}
          deviceTypeRefetch={refetch}
          setShowPublishDeviceModal={setShowPublishDeviceModal}
        />
      )}
      <Table
        {...collectionProps}
        onSelectionChange={({ detail }) => setSelectedDeviceType(detail.selectedItems)}
        selectedItems={selectedDeviceType}
        loading={loading}
        wrapLines={true}
        stripedRows={true}
        resizableColumns={true}
        columnDefinitions={COLUMN_DEFINITIONS}
        items={items}
        loadingText="Loading resources"
        selectionType="single"
        trackBy="name"
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringPlaceholder="Find resources"
          />
        }
        header={
          <Header
            counter={`(${selectedDeviceType?.length || 0}/${allItems.length})`}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  onClick={() =>
                    navigate('/device-type/create', {
                      state: {
                        action: 'create',
                      },
                    })
                  }
                >
                  Create
                </Button>
                <ButtonDropdown
                  items={[
                    {
                      text: 'Delete',
                      id: 'device-type-delete',
                      disabled: allItems?.length === 0,
                    },
                    {
                      text: 'Edit',
                      id: 'device-type-edit',
                      disabled: selectedDeviceType![0]?.attributes?.state !== 'active',
                    },
                    {
                      text: 'Publish',
                      id: 'device-type-publish',
                      disabled: selectedDeviceType![0]?.attributes?.state !== 'registered',
                    },
                  ]}
                  onItemClick={handleButtonDropdownClick}
                >
                  Actions
                </ButtonDropdown>
              </SpaceBetween>
            }
          >
            List of device types
          </Header>
        }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
      />

      <DeleteModal
        visible={showDeleteModal}
        onDiscard={onDeleteDiscard}
        onDelete={onDeleteConfirm}
        itemName={selectedDeviceType![0]?.name}
        itemCount={selectedDeviceType?.length}
        moduleName="Device Type"
      />
    </>
  );
};

export default DeviceTypesTable;
