import {
  SpaceBetween,
  Button,
  Header,
  FormField,
  Input,
  Form,
  Select,
  SelectProps,
} from '@cloudscape-design/components';
import { catalogAPI } from 'api';
import Spinner from 'components/spinner/Spinner';
import {
  API_URL_PATH_LIST_DEPLOYMENTS,
  API_URL_PATH_LIST_CATALOG_ACCOUNTS,
  API_URL_PATH_LIST_SHOPPING_ACCOUNTS,
  API_URL_PATH_LIST_SHOPPING_DEPLOYMENTS,
  API_URL_PATH_LIST_SHOPPING_TEMPLATES,
  API_URL_PATH_LIST_TEMPLATES,
  URL_PATH_CATALOG_MANAGER,
  URL_PATH_CATALOG_SHOPPING,
  URL_PATH_CREATE_CATALOG_DEPLOYMENT,
} from 'constants/urls';
import useFetch from 'hooks/useFetch';
import useFetchWithReactQuery from 'hooks/useFetchWithReactQuery';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { checkKeyDown, selectAccountsProps, selectTemplateProps } from 'utils';
import { TemplateDto } from 'types/custom';

const CreateDeployment = () => {
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [description, setDescription] = useState('');
  const [selectedAwsAccounts, setSelectedAwsAccount] = useState<any>('');
  const [selectedTemplate, setSelectedTemplate] = useState<SelectProps.Option>({
    value: '',
    label: '',
  });
  const [region, setRegion] = useState('');
  const [disableFormFields, setDisableFormFields] = useState(false);

  const [templateList, setTemplateList] = useState([]);
  const [templateParameters, setTemplateParameters] = useState<{ [parameterName: string]: string }>({});

  const [regAccountsList, setRegAccountsList] = useState([]);

  const [isEditFlow, setIsEditFlow] = useState(false);
  const [flowType, setFlowType] = useState('manager');


  const navigate = useNavigate();
  const location = useLocation();
  let params = useParams();

  useEffect(() => {
    setFlowType('manager');

    if (
      (location && location.state?.action === 'edit') ||
      (location.pathname && location.pathname.indexOf('edit') > -1)
    ) {
      setIsEditFlow(true);
    } else {
      setIsEditFlow(false);
    }
  }, []);

  const {
    loading: applicationLoading,
    error: applicationError,
    fetchData: getApplication,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: flowType === 'manager' ? API_URL_PATH_LIST_DEPLOYMENTS : API_URL_PATH_LIST_SHOPPING_DEPLOYMENTS,
      param: params?.iotApplicationId,
    },
    { manual: true }
  );

  const {
    loading: accountsLoading,
    error: accountsError,
    response: accountsResponse,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: flowType === 'manager' ? API_URL_PATH_LIST_CATALOG_ACCOUNTS : API_URL_PATH_LIST_SHOPPING_ACCOUNTS,
    },
    { manual: false }
  );

  useEffect(() => {
    if (accountsResponse) {
      const accountsOptions = accountsResponse?.items?.map(selectAccountsProps);
      setRegAccountsList(accountsOptions);
    }
  }, [accountsResponse]);

  useEffect(() => {
    if (isEditFlow) {
      getApplication()
        .then((application) => {
          if (application?.data) {
            setName(application.data.name);
            setDescription(application.data.description);
            setId(application.data.id);
          } else {
            const navUrl =
              flowType === 'manager' ? URL_PATH_CREATE_CATALOG_DEPLOYMENT : API_URL_PATH_LIST_SHOPPING_DEPLOYMENTS;
            navigate(navUrl);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isEditFlow]);

  const {
    error: updateAppError,
    loading: updateAppLoading,
    status: updateAppStatus,
    fetchData: updateApp,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'PATCH',
      url: `${flowType === 'manager' ? API_URL_PATH_LIST_DEPLOYMENTS : API_URL_PATH_LIST_SHOPPING_DEPLOYMENTS}/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        name,
        description,
      },
    },
    { manual: true }
  );

  const { error, loading, status, fetchData } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'POST',
      url: API_URL_PATH_LIST_DEPLOYMENTS,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        templateId: selectedTemplate?.value,
        name,
        description,
        region: region,
        accountId: selectedAwsAccounts.value,
        parameters: (templateParameters),
      },
    },
    { manual: true }
  );

  const {
    response: templateListResponse,
    loading: templateLoading,
    error: templateError,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: flowType === 'manager' ? API_URL_PATH_LIST_TEMPLATES : API_URL_PATH_LIST_SHOPPING_TEMPLATES,
    },
    { manual: false }
  );

  useEffect(() => {
    if (templateListResponse) {
      const templateOptions = templateListResponse?.items?.map(selectTemplateProps);
      setTemplateList(templateOptions);
    }
  }, [templateListResponse]);

  useEffect(() => {
    if (status === 201 || updateAppStatus === 200) {
      let stateObj = {};

      if (isEditFlow) {
        stateObj = {
          action: 'update-deployment',
          status: 'success',
          message: `Successfully updated deployment ${name}`,
          tab: 'deployments',
        };
      } else {
        stateObj = {
          action: 'create-deployment',
          status: 'success',
          message: `Successfully created deployment ${name}`,
          tab: 'deployments',
        };
      }

      const navUrl = flowType === 'manager' ? URL_PATH_CATALOG_MANAGER : URL_PATH_CATALOG_SHOPPING;
      navigate(navUrl, {
        state: stateObj,
      });
    } else if (status > 201 || updateAppStatus > 201) {
      setDisableFormFields(false);
    }
  }, [status, updateAppStatus]);


  const { data: templateResponse } = useFetchWithReactQuery<TemplateDto>({
    key: `template-${selectedTemplate.value}`,
    url: API_URL_PATH_LIST_TEMPLATES + `/${selectedTemplate.value}`,
    axiosInstance: catalogAPI,
    enabled: !!selectedTemplate.value,
  });

  useEffect(() => {
    if (!!selectedTemplate.value) {
      templateResponse.requiredParameters.forEach((parameter: string) => templateParameters[`${parameter}`] = '');
      setTemplateParameters({ ...templateParameters });
    }

  }, [templateResponse])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableFormFields(true);
    setTemplateParameters({ ...templateParameters });

    if (isEditFlow) {
      updateApp();
    } else {
      fetchData();
    }
  };

  return (
    <form onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="link" onClick={() => navigate(URL_PATH_CATALOG_MANAGER, {})}>
              Cancel
            </Button>
            <Button variant="primary" loading={loading || updateAppLoading}>
              Submit
            </Button>
          </SpaceBetween>
        }
        header={
          <Header variant="h2" description="">
            {isEditFlow ? 'Update' : 'Create'} a Deployment
          </Header>
        }
        errorText={error || applicationError || updateAppError}
      >
        <SpaceBetween direction="vertical" size="l">
          {applicationLoading ? (
            <Spinner />
          ) : (
            <>
              <FormField label="Name">
                <Input disabled={disableFormFields} value={name} onChange={(event) => setName(event.detail.value)} />
              </FormField>
              <FormField label="Description">
                <Input
                  disabled={disableFormFields}
                  value={description}
                  onChange={(event) => setDescription(event.detail.value)}
                />
              </FormField>

              <FormField label="AWS Account ID">
                <Select
                  selectedOption={selectedAwsAccounts}
                  onChange={({ detail }) => {
                    setSelectedAwsAccount(detail.selectedOption);
                  }}
                  options={regAccountsList}
                  loadingText="Loading accounts"
                  placeholder="Choose an account"
                  statusType={accountsLoading ? 'loading' : 'finished'}
                  errorText={accountsError}
                  disabled={disableFormFields}
                  empty="No registered accounts"
                />
              </FormField>
            </>
          )}
          {!isEditFlow && (
            <>
              <FormField label="Template">
                <Select
                  selectedOption={selectedTemplate}
                  onChange={({ detail }) => {
                    setSelectedTemplate(detail.selectedOption);
                  }}
                  options={templateList}
                  loadingText="Loading instances"
                  placeholder="Choose a template"
                  statusType={templateLoading ? 'loading' : 'finished'}
                  errorText={templateError}
                  disabled={disableFormFields}
                  empty="No templates"
                />
              </FormField>
              {Object.keys(templateParameters).length > 0 &&
                Object.keys(templateParameters).map((parameter: string, idx: number) =>
                  <FormField label={parameter} key={idx} >
                    <Input
                      disabled={disableFormFields}
                      value={templateParameters[parameter]}
                      onChange={(event) => {
                        templateParameters[parameter] = (event.detail.value)
                        setTemplateParameters({ ...templateParameters })
                      }}
                    />
                  </FormField>
                )
              }
              <FormField label="Region">
                <Input
                  disabled={disableFormFields}
                  value={region}
                  onChange={(event) => setRegion(event.detail.value)}
                />
              </FormField>
            </>
          )}
        </SpaceBetween>
      </Form>
    </form>
  );
};

export default CreateDeployment;
