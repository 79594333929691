import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ColumnLayout } from '@cloudscape-design/components';

import PhysicalDevicesTable from 'components/physical-devices/PhysicalDevicesTable';
import PhysicalDevicesTabs from 'components/physical-devices/PhysicalDevicesTabs';
import useFetch from 'hooks/useFetch';
import Spinner from 'components/spinner/Spinner';
import { usePageLayoutContext } from 'components/common/layout';

import { IDevicesListProps, IDevicesResponse } from 'types/custom';
import { API_URL_PATH_PHYSICAL_DEVICE } from 'constants/urls';
import { platformCoreAPI } from 'api';
import { extractFilteringAttributes } from 'utils';

const PhysicalDevicesContext = createContext({
  allItems: [] as any[],
  loading: false,
  error: '',
  setSelectedItem: (state: IDevicesListProps[]) => { },
  selectedItem: [] as IDevicesListProps[] | undefined,
  refetch: () => { },
  activeTabId: '',
  setActiveTabId: (state: string) => { },
});
export const usePhysicalDevicesContext = () => useContext(PhysicalDevicesContext);

const ListPhysicalDevicesPage = () => {
  const [selectedItem, setSelectedItem] = useState<IDevicesListProps[] | undefined>([]);
  const [allItems, setAllItems] = useState<any[]>([]);
  const [activeTabId, setActiveTabId] = useState<string>('details');

  const navigate = useNavigate();
  const location = useLocation();

  const { setNotification } = usePageLayoutContext();

  useEffect(() => {
    if (location?.state?.action) {
      setNotification([
        {
          type: 'success',
          content: location.state.message,
        },
      ]);

      if (location.state.action === 'create-mqtt') {
        setActiveTabId(location?.state?.activeTabId);
        setSelectedItem(location?.state?.selectedItem);
      }

      navigate(location.pathname, {});
    }
  }, [location?.state?.action]);

  const {
    response,
    loading,
    status,
    error,
    refetch,
  }: {
    response: IDevicesResponse;
    loading: boolean;
    status: number;
    error: string;
    refetch: () => void;
  } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'GET',
      url: API_URL_PATH_PHYSICAL_DEVICE,
      headers: {
        accept: '*/*',
      },
    },
    { manual: false }
  );

  useEffect(() => {
    if (status === 200) {
      setAllItems(extractFilteringAttributes(response.results));
    }
  }, [status, response]);

  return (
    <PhysicalDevicesContext.Provider
      value={{
        loading,
        allItems,
        error,
        setSelectedItem,
        selectedItem,
        refetch,
        activeTabId,
        setActiveTabId,
      }}
    >
      <ColumnLayout variant="text-grid">
        {loading && (
          <>
            <br></br>
            <Spinner />
          </>
        )}
        {response && <PhysicalDevicesTable />}
      </ColumnLayout>

      {response && selectedItem![0]?.name !== undefined && (
        <>
          <ColumnLayout variant="text-grid">
            <PhysicalDevicesTabs />
          </ColumnLayout>
        </>
      )}
    </PhysicalDevicesContext.Provider>
  );
};

export default ListPhysicalDevicesPage;
