import { SpaceBetween, Button, Header, FormField, Input, Form, Alert } from '@cloudscape-design/components';
import { platformCoreAPI } from 'api';
import { API_URL_PATH_DEVICE_TYPE, URL_FULL_PATH_LIST_DEVICE_TYPE } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const CreateDeviceType = () => {
  const [id, setId] = useState<string>('');
  const [friendlyName, setFriendlyName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [manufacturer, setManufacturer] = useState<string>('');
  const [snowId, setSnowId] = useState<string>('');
  const [disableFormFields, setDisableFormFields] = useState<boolean>(true);

  const [firmwareVersion, setFirmwareVersion] = useState<string>('');
  const [hardwareVersion, setHardwareVersion] = useState<string>('');
  const [normalizer, setNormalizer] = useState<string>('');
  const [decoder, setDecoder] = useState<string>('');

  const navigate = useNavigate();
  const location = useLocation();
  let params = useParams();
  const flowType = location.pathname.indexOf('create') !== -1 ? 'create' : 'update';

  useEffect(() => {
    if (flowType === 'create') setDisableFormFields(false);

    setId(params?.deviceTypeName || '');
  }, []);

  const { response: getDeviceTypeResponse, status: getDeviceTypeStatus } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'GET',
      url: API_URL_PATH_DEVICE_TYPE,
      param: params?.deviceTypeName,
    },
    { manual: flowType === 'create' }
  );

  useEffect(() => {
    if (getDeviceTypeStatus === 200) {
      setDisableFormFields(false);
      setDescription(getDeviceTypeResponse?.description);
      setFriendlyName(getDeviceTypeResponse?.attributes.friendlyName);
      setManufacturer(getDeviceTypeResponse?.attributes.manufacturer);
      setNormalizer(getDeviceTypeResponse?.attributes.normalizer);
      setDecoder(getDeviceTypeResponse?.attributes.decoder);
    }
  }, [getDeviceTypeStatus, getDeviceTypeResponse]);

  const {
    error: updateDTError,
    loading: updateDTLoading,
    status: updateDTStatus,
    fetchData: updateDT,
  } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'PATCH',
      url: API_URL_PATH_DEVICE_TYPE,
      param: params?.deviceTypeName,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        friendlyName,
        description,
        manufacturer,
        //purpose,
        firmwareVersion,
        hardwareVersion,
        normalizer,
        decoder,
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (updateDTStatus === 200) {
      navigate(URL_FULL_PATH_LIST_DEVICE_TYPE, {
        state: {
          action: 'update-device-type',
          status: 'success',
          message: `Successfully updated device type ${friendlyName}`,
        },
      });
    } else if (updateDTStatus > 200) {
      setDisableFormFields(false);
    }
  }, [updateDTStatus]);

  const { error, loading, status, fetchData } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'POST',
      url: API_URL_PATH_DEVICE_TYPE,
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
      data: {
        snowId,
        id,
        friendlyName,
        description,
        manufacturer,
        //purpose,
        //deviceClass,
        //associatedPhysicalDeviceType,
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (status === 201) {
      navigate(URL_FULL_PATH_LIST_DEVICE_TYPE, {
        state: {
          action: 'create-device-type',
          status: 'success',
          message: `Successfully created device type ${friendlyName}`,
        },
      });
    } else if (status > 201) {
      setDisableFormFields(false);
    }
  }, [status]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableFormFields(true);
    if (flowType === 'create') {
      fetchData();
    } else {
      updateDT();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="link" onClick={() => navigate(URL_FULL_PATH_LIST_DEVICE_TYPE, {})}>
              Cancel
            </Button>
            <Button variant="primary" loading={loading || updateDTLoading}>
              Submit
            </Button>
          </SpaceBetween>
        }
        header={
          <Header variant="h2" description="">
            {flowType === 'create' ? 'Create' : 'Update'} Device Type
          </Header>
        }
        errorText={error || updateDTError}
      >
        <SpaceBetween direction="vertical" size="l">
          <FormField label="Device Type ID">
            <Input
              disabled={disableFormFields || flowType === 'update'}
              value={id}
              onChange={(event) => setId(event.detail.value)}
            />
          </FormField>
          <FormField label="Friendly name">
            <Input
              disabled={disableFormFields}
              value={friendlyName}
              onChange={(event) => setFriendlyName(event.detail.value)}
            />
          </FormField>
          <FormField label="Description">
            <Input
              disabled={disableFormFields}
              value={description}
              onChange={(event) => setDescription(event.detail.value)}
            />
          </FormField>
          <FormField label="Manufacturer">
            <Input
              disabled={disableFormFields}
              value={manufacturer}
              onChange={(event) => setManufacturer(event.detail.value)}
            />
          </FormField>

          {flowType === 'create' && (
            <>
              <FormField label="ServiceNOW ID">
                <Input
                  disabled={disableFormFields}
                  value={snowId}
                  onChange={(event) => setSnowId(event.detail.value)}
                />
              </FormField>
              <Alert statusIconAriaLabel="Warning">
                Device Types should always be created from ServiceNOW. This form is only provided as a fallback.
              </Alert>
              {/* <FormField label='Purpose'>
                                <Input
                                    disabled={disableFormFields}
                                    value={purpose}
                                    onChange={(event) =>
                                        setPurpose(event.detail.value)
                                    }
                                />
                            </FormField>
                            <FormField label='Device Class'>
                                <Input
                                    disabled={disableFormFields}
                                    value={deviceClass}
                                    onChange={(event) =>
                                        setDeviceClass(event.detail.value)
                                    }
                                />
                            </FormField>
                            <FormField label='Associated Physical Device Type'>
                                <Input
                                    disabled={disableFormFields}
                                    value={associatedPhysicalDeviceType}
                                    onChange={(event) =>
                                        setAssociatedPhysicalDeviceType(
                                            event.detail.value
                                        )
                                    }
                                />
                            </FormField> */}
            </>
          )}

          {flowType === 'update' && (
            <>
              <FormField label="Firmware Version">
                <Input
                  disabled={disableFormFields}
                  value={firmwareVersion}
                  onChange={(event) => setFirmwareVersion(event.detail.value)}
                />
              </FormField>
              <FormField label="Hardware Version">
                <Input
                  disabled={disableFormFields}
                  value={hardwareVersion}
                  onChange={(event) => setHardwareVersion(event.detail.value)}
                />
              </FormField>
              <FormField label="Normalizer">
                <Input
                  disabled={disableFormFields}
                  value={normalizer}
                  onChange={(event) => setNormalizer(event.detail.value)}
                />
              </FormField>
              <FormField label="Decoder">
                <Input
                  disabled={disableFormFields}
                  value={decoder}
                  onChange={(event) => setDecoder(event.detail.value)}
                />
              </FormField>
            </>
          )}
        </SpaceBetween>
      </Form>
    </form>
  );
};

export default CreateDeviceType;
